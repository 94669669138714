<template>
  <v-card class="elevation-1 pa-3 login-card">
    <v-card-text>
      <div class="layout column align-center">
        <h1 class="flex my-4 primary--text font-weight-bold">ログイン</h1>
      </div>

      <div v-if="loginErrors" class="errors" style="color: red">
        <ul v-if="loginErrors.message">
          <li>{{ loginErrors.message }}</li>
        </ul>
        <ul v-if="loginErrors.email">
          <li v-for="msg in loginErrors.email" :key="msg">{{ msg }}</li>
        </ul>
        <ul v-if="loginErrors.password">
          <li v-for="msg in loginErrors.password" :key="msg">{{ msg }}</li>
        </ul>
      </div>

      <v-form ref="loginForm">
        <v-text-field
          name="login"
          label="ログインID"
          type="text"
          v-model="model.email"
          :counter="255"
          :rules="emailRules"
          required
        ></v-text-field>
        <v-text-field
          name="password"
          label="パスワード"
          id="password"
          type="password"
          v-model="model.password"
          :counter="255"
          :rules="passwordRules"
          required
        ></v-text-field>
      </v-form>
    </v-card-text>
    <div class="login-btn">
      <v-btn block color="primary" @click="login" :loading="loading"
        >ログイン</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      emailRules: [
        (v) => !!v || "ログインIDは必須項目です。",
        (v) =>
          (v && v.length <= 255) ||
          "ログインIDは255文字以内で入力してください。",
      ],
      emailRules2: [
        (v) => !!v || "ログインIDは必須項目です。",
        (v) =>
          (v && v.length <= 255) ||
          "メールアドレスは255文字以内で入力してください。",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "メールアドレスの形式が正しくありません。",
      ],
      passwordRules: [
        (v) => !!v || "パスワードは必須項目です。",
        (v) =>
          (v && v.length <= 255) ||
          "パスワードは255文字以内で入力してください。",
      ],
      model: {
        email: "",
        password: "",
      },
    };
  },
  watch: {
    user: function(val, oldVal) {
      if (val) {
        this.$router.push("/");
      }
    },
  },
  computed: mapState({
    apiStatus: (state) => state.auth.apiStatus,
    loginErrors: (state) => state.auth.loginErrorMessages,
    user: (state) => state.auth.user,
  }),
  mounted() {
    // csrf対策
    // nginxでRPする場合は/sanctumがapi側を見に行くようにしてください
    //this.$axios.get('/sanctum/csrf-cookie');
    this.$axios.get("/sanctum/csrf-cookie");
  },
  methods: {
    async login() {
      // authストアのloginアクションを呼び出す
      await this.$store.dispatch("auth/login", this);
      if (this.apiStatus) {
        // トップページに移動する
        console.log(this.apiStatus);
        // this.$router.push('/sensorgraph5/')
      }
    },
    clearError() {
      this.$store.commit("auth/setLoginErrorMessages", null);
    },
  },
  created() {
    this.clearError();
  },
};
</script>
