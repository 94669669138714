<template>
  <v-container>
    <!--入力フォーム-->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container>
          <v-alert dense outlined type="error" v-if="error_items">
            <ul id="example-1">
              <li v-for="item in error_items" :key="item.id">
                {{ item }}
              </li>
            </ul>
          </v-alert>

          <h2 v-if="isCreate">新規作成</h2>
          <h2 v-if="!isCreate">更新</h2>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!--表示名-->
            <v-text-field
              v-model="customer.name"
              :rules="nameRules"
              :counter="20"
              label="表示名"
              required
            ></v-text-field>
            <!--ログインID-->
            <v-text-field
              v-model="customer.email"
              :rules="emailRules"
              :counter="50"
              label="ログインID"
              required
            ></v-text-field>
            <!--パスワード-->
            <v-text-field
              v-if="isCreate"
              type="password"
              v-model="customer.password"
              :rules="passwordRules"
              :counter="100"
              label="パスワード"
              required
            ></v-text-field>
            <v-text-field
              v-else
              type="password"
              v-model="customer.password"
              :counter="100"
              label="パスワード (変更時のみ入力)"
              required
            ></v-text-field>
            <v-select
              v-model="customer.role"
              :items="roles"
              :rules="roleRules"
              item-text="label"
              item-value="value"
              label="権限"
              dense
              return-object
            ></v-select>
            <!--追加ボタン-->
            <v-btn v-if="isCreate" :disabled="!valid" @click="createCustomer">
              追加
            </v-btn>
            <!--更新ボタン-->
            <v-btn v-if="!isCreate" :disabled="!valid" @click="updateCustomer">
              更新
            </v-btn>
            <v-btn @click="clear">クリア</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <v-col cols="12" sm="12" class="" style="text-align:center;" v-if="loading">
      <v-progress-linear
        v-if="loading"
        style="margin-top: 0px;"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>

    <!--ツールバー-->
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>ユーザー</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="showDialogNew"
        >新規追加</v-btn
      >
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :footer-props="{
        itemsPerPageOptions: [itemsPerPage],
        showFirstLastPage: true,
      }"
      class="elevation-1"
      @update:page="fetchUsers"
    >
      <template v-slot:item.roles="{ item }">
        {{ roleName[item.role] }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="showDialogEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteConfirm(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <!-- 削除確認ダイアログを追加 -->
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">削除確認</v-card-title>
        <v-card-text>ID:{{ deleteID }}を削除してもよろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false"
            >キャンセル</v-btn
          >
          <v-btn color="green darken-1" text @click="deleteItem(deleteID)"
            >削除</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { OK, UNPROCESSABLE_ENTITY } from "../util";
export default {
  data() {
    return {
      error_items: null,
      roleName: {
        "100": "スーパー管理者",
        "50": "管理者",
        "10": "一般",
        "5": "公開",
        "1": "一時停止",
      },
      roles: [
        { label: "スーパー管理者", value: "100" },
        { label: "管理者", value: "50" },
        { label: "一般", value: "10" },
        { label: "公開", value: "5" },
        { label: "一時停止", value: "1" },
      ],
      loading: false,
      /*
      model: {
        email: "",
        password: "",
      },
      */
      headers: [
        { text: "ID", value: "id", align: "start", sortable: false },
        { text: "ログインID", value: "email", sortable: false },
        { text: "表示名", value: "name", sortable: false },
        { text: "権限", value: "roles", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      page: 1,
      itemsPerPage: 20,
      total: 0,
      items: [],
      deleteDialog: false, // 追加：初期値は非表示
      deleteID: null, // 追加：削除Itemのid

      // フォーム入力値
      customer: {
        id: "",
        name: "",
        email: "",
        password: "",
        role: "10",
      },
      // バリデーション
      valid: true,
      nameRules: [
        (v) => !!v || "名前は必須項目です",
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      emailRules: [
        (v) => !!v || "ログイン IDは必須項目です",
        (v) => (v && v.length <= 50) || "50文字以内で入力してください",
        (v) =>
          /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/.test(
            v
          ) || "半角英数で入力してください。",
      ],
      passwordRules: [
        (v) => !!v || "パスワードは必須項目です",
        (v) => (v && v.length <= 100) || "100文字以内で入力してください",
      ],
      roleRules: [(v) => !!v || "権限は必須項目です"],
      // ローディングの表示フラグ
      progress: false,
      // ダイアログの表示フラグ
      dialog: false,
      // 新規・更新のフラグ
      isCreate: true,
    };
  },
  mounted() {
    // csrf対策
    // nginxでRPする場合は/sanctumがapi側を見に行くようにしてください
    //this.$axios.get('/sanctum/csrf-cookie');
  },
  methods: {
    deleteConfirm(id) {
      this.deleteDialog = true;
      this.deleteID = id;
    },
    async loginCheck() {
      console.log("### loginCheck");
      await this.$store.dispatch("auth/currentUser", this);
      //await store.dispatch('auth/currentUser', this)
      // トップページに移動する
      // this.$router.push('/');
    },
    // --------------------------------
    // フォームのクリア
    // --------------------------------
    clear: function() {
      this.$refs.form.reset();
    },
    // --------------------------------
    // ダイアログの表示
    // --------------------------------
    showDialogNew: function() {
      this.error_items = null;
      this.isCreate = true;
      this.dialog = true;
      this.error_items = false;

      this.customer.id = '';
      this.customer.name = '';
      this.customer.email = '';
      this.customer.password = '';
      this.$refs.form.resetValidation();

      //this.clear(); // MUST this.dialog = true;
    },
    showDialogEdit(item) {
      // editItem(item) {
      this.customer.id = item.id;
      this.customer.name = item.name;
      this.customer.email = item.email;
      this.customer.password = "";
      this.customer.role = String(item.role);
      this.isCreate = false;
      this.dialog = true;
      this.error_items = false;
      this.$refs.form.resetValidation();
      //this.clear(); // MUST this.dialog = true;
    },
    deleteCustomer: function(name, id) {
      if (!confirm(name + id + "さんを削除してもよろしいですか？")) {
        return;
      }
    },
    // --------------------------------
    // API接続
    // --------------------------------
    async createCustomer() {
      this.$axios.get("/sanctum/csrf-cookie");
      const role = this.customer.role.value
        ? this.customer.role.value
        : this.customer.role;
      const response = await this.$axios
        .post("/api/users", {
          name: this.customer.name,
          email: this.customer.email,
          password: this.customer.password,
          role: role,
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.dialog = false;
        this.progress = false;
        await this.fetchUsers();
        return false;
      }
      this.progress = false;
      if (response.status === UNPROCESSABLE_ENTITY) {
        this.error_items = response.data.errors;
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        this.dialog = false;
        this.progress = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    async updateCustomer() {
      const role = this.customer.role.value
        ? this.customer.role.value
        : this.customer.role;
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .put(
          `/api/users/${this.customer.id}`,
          {
            name: this.customer.name,
            // role: String(this.customer.role.value),
            role: role,
            password: this.customer.password,
            email: this.customer.email,
          },
          {
            headers: {
              "X-HTTP-Method-Override": "PUT",
            },
          }
        )
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.dialog = false;
        this.progress = false;
        await this.fetchUsers();
        return false;
      }
      this.progress = false;
      if (response.status === UNPROCESSABLE_ENTITY) {
        this.error_items = response.data.errors;
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        this.dialog = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    deleteItem(id) {
      this.$axios
        .delete("/api/users/" + id)
        .then((response) => {
          if (response.status === OK) {
            this.fetchUsers();
            return false;
          }
          this.$store.commit("error/setCode", response.status, { root: true });
        })
        .catch(function(error) {
          console.error(error);
        });
      this.deleteDialog = false;
    },
    async fetchUsers() {
      this.loading = true;
      const response = await this.$axios.get(`/api/users/?page=${this.page}`);
      this.loading = false;
      if (response.status !== OK) {
        this.$store.commit("error/setCode", response.status);
        return false;
      }
      this.items = response.data.items;
      this.currentPage = response.data.current_page;
      this.lastPage = response.data.last_page;
    },
  },
  beforeCreate() {
    // console.log("### Sensors beforeCreate ");
  },
  created() {
    // console.log("### Sensors Created ");
    this.loginCheck();
  },
  watch: {
    $route: {
      async handler() {
        await this.fetchUsers();
      },
      immediate: true,
    },
  },
};
</script>
