<template>
  <v-app dark>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="clipped"
      fixed
      app
      temporary
    >
      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action v-if="item.role <= role">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="item.role <= role">
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="clipped" fixed app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <div v-if="username">
        <v-icon>mdi-account</v-icon
        ><span style="margin-left:0px; margin-right:15px;"
          >{{ role }}:{{ username }}</span
        ><v-btn color="primary" elevation="2" outlined @click="logout"
          >Logout</v-btn
        >
      </div>
      <v-btn v-else color="primary" elevation="2" outlined to="/login"
        >Login</v-btn
      >
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <!--<v-navigation-drawer
      v-model="rightDrawer"
      :right="right"
      temporary
      fixed
    >
      <v-list>
        <v-list-item @click.native="right = !right">
          <v-list-item-action>
            <v-icon light>
              mdi-repeat
            </v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>-->

    <Footer />
    <!--
    <v-footer
      :absolute="!fixed"
      app
    >
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
-->
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";
import {
  NOT_FOUND,
  UNAUTHORIZED,
  UNAUTHORIZED2,
  INTERNAL_SERVER_ERROR,
} from "./util";

export default {
  components: {
    Footer,
  },
  computed: {
    isLogin() {
      return this.$store.getters["auth/check"];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    role() {
      return this.$store.getters["auth/role"];
    },
    errorCode() {
      return this.$store.state.error.code;
    },
  },
  methods: {
    async logout() {
      // authストアのloginアクションを呼び出す
      await this.$store.dispatch("auth/logout", this);
      // トップページに移動する
      // this.$router.push('/');
    },
  },
  watch: {
    errorCode: {
      async handler(val) {
        //console.log("### errorCode App.vue ###");
        //console.log(val);
        if (val === INTERNAL_SERVER_ERROR) {
          this.$router.push("/500");
        } else if (val === UNAUTHORIZED || val === UNAUTHORIZED2) {
          console.log("### errorCode App.vue 2");
          this.$store.commit("auth/setUser", null);
          this.$router.push("/login");
        } else if (val === NOT_FOUND) {
          this.$router.push("/not-found");
        }
      },
      immediate: true,
    },
    $route() {
      this.$store.commit("error/setCode", null);
    },
  },
  created() {
    // Permalink Re-get userinfo
    // console.log("### created 1 App.vue");
    if (!this.$store.username) {
      this.$store.dispatch("auth/currentUser", this);
    }
    // console.log("### created 2 App.vue");
  },
  mounted() {
    // console.log("### mounted 1 App.vue");
  },
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: "mdi-chart-bubble",
          title: "MyTeams",
          to: "/myteams",
          role: 5,
        },
        {
          icon: "mdi-chart-bubble",
          title: "[Admin] Users",
          to: "/users",
          role: 100,
        },
        {
          icon: "mdi-chart-bubble",
          title: "[Admin] Teams",
          to: "/teams",
          role: 100,
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "",
    };
  },
};
</script>
