<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="6" sm="3" md="2" class="datecol">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="開始日を選択"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            :events="arrayEvents"
            event-color="green lighten-1"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu1 = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="dateDone1(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" sm="2" md="2">
        <vue-timepicker
          manual-input
          input-width="100px"
          style="margin-top:0px"
          v-model="start_time"
          hide-clear-button
        ></vue-timepicker>
      </v-col>

      <v-col cols="6" sm="3" md="2" class="datecol">
        <!--<v-col cols="4" style="background-color: #FF00FF; padding-top: 25px;">-->
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="終了日を選択"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              width="500px"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            :events="arrayEvents"
            event-color="green lighten-1"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="dateDone2(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" sm="2" md="2">
        <vue-timepicker
          manual-input
          input-width="100px"
          style="margin-top:0px"
          v-model="end_time"
          hide-clear-button
        ></vue-timepicker>
        <!--<div>
         <v-progress-circular
          v-if="loading"
          indeterminate
          color="green"
        ></v-progress-circular></div>-->
      </v-col>

      <v-col cols="12" sm="2" md="2" style="">
        <v-btn
          style="margin-top:0px;"
          color="primary"
          dark
          @click="loadBtnClicked('load', $event)"
          >Load</v-btn
        >
        <!--<v-progress-linear
              v-if="loading"
              style="margin-top: 10px;"
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>-->
      </v-col>
      <!--<v-col cols="3" sm="0" md="2" class="progress">
      </v-col>-->
      <v-col cols="12" class="btnbox" style="">
        <!--<div class="maru_ss" style="margin:0px; ">
          <span>{{ nowItem }}</span>
        </div>-->
        <v-avatar
          color="teal"
          size="28"
          style="margin-right: 14px; color:#FFF;"
          >{{ nowItem }}</v-avatar
        >

        <!--<v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area1', $event)"
          >エリア1</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area2', $event)"
          >エリア2</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area3', $event)"
          >エリア3</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area4', $event)"
          >エリア4</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area5', $event)"
          >エリア5</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area6', $event)"
          >エリア6</v-btn
        >
        <v-btn
          rounded
          x-small
          color="warning"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('differential', $event)"
          >differential</v-btn
        >-->
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('temp', $event)"
          >気温</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('pressure', $event)"
          >気圧</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('humidity', $event)"
          >湿度</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('co2', $event)"
          >CO2</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('tvoc', $event)"
          >tvoc</v-btn
        >
        <!--<div class="maru_ss" style="margin:0px; ">
          <span>{{ nowItem }}</span>
        </div>-->
      </v-col>
      <v-col cols="12" md="12">
        <line-chart
          class="chartbox"
          ref="graph1"
          :chart-data="datacollection"
          :options="options"
          :height="400"
          v-on:chart:update="graphOnLoaded"
          v-on:chart:render="graphOnLoaded"
        ></line-chart>
        <span class="time">{{ formatTime }}</span>
        <v-btn
          v-if="!timerOn"
          outlined
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="reloadTimer('on', $event)"
          >Auto Reload ON</v-btn
        >
        <v-btn
          v-if="timerOn"
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="reloadTimer('off', $event)"
          >Auto Reload OFF</v-btn
        >
        <v-btn
          outlined
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="zoomReset('zoom', $event)"
          >Zoom Reset</v-btn
        >
        <v-btn
          v-if="role >= 10"
          color="primary"
          class="ma-2 white--text"
          fab
          x-small
          @click="csvDL"
        >
          <v-icon dark>
            mdi-cloud-download
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="8" lg="8">
        <div ref="map" class="mapbox" style=""></div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        lg="4"
        style="background-color: #FFFFFF; text-align:left;"
      >
        <v-progress-linear
          v-if="loading"
          style="margin-top: 10px;"
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
        <v-data-table
          :headers="headers"
          :items="marker_items"
          style="margin-top:-48px;"
          class="narrow"
        >
          <!--<template v-slot:item.info="{ item }">
            <v-icon class="mr-2" color="green" dense v-if="item.longitude">
              mdi-compass
            </v-icon>
            <v-icon class="mr-2" color="red" dense v-else>
              mdi-compass-off
            </v-icon>
            <v-icon class="mr-2" color="red" dense v-if="item.time_diff.hours">
              mdi-antenna
            </v-icon>
            <v-icon class="mr-2" color="green" dense v-else>
              mdi-antenna
            </v-icon>
                 </template>-->

          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2" color="green" dense v-if="item.longitude">
              mdi-compass
            </v-icon>
            <v-icon class="mr-2" color="red" dense v-else>
              mdi-compass-off
            </v-icon>
            <v-icon class="mr-2" color="red" dense v-if="item.time_diff.hours">
              mdi-antenna
            </v-icon>
            <v-icon class="mr-2" color="green" dense v-else>
              mdi-antenna
            </v-icon>
            <v-icon class="mr-2" @click="zoomMapByItem(item)">
              mdi-magnify
            </v-icon>
            <v-icon class="mr-2" @click="redirectByItem(item)">
              mdi-chart-line
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { OK, UNPROCESSABLE_ENTITY } from "../util";
import LineChart from "../components/Chart";
import dayjs from "dayjs";
// import Timeselector from 'vue-timeselector';
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    LineChart,
    VueTimepicker,
  },
  watch: {
    $route: {
      async handler() {
        if (this.start_date && this.end_date && this.$route.params.id) {
          this.fetchSensorDate();
          this.fetchSensorData();
        }
        // await this.fetchSensors();
      },
      immediate: true,
    },
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
  },
  data() {
    return {
      min: 1,
      sec: 0,
      timerObj: null,
      timerOn: false,
      start_time: "00:00",
      end_time: "00:00",
      start_date: "",
      end_date: "",
      diffDate: 0,
      arrayEvents: [],
      nowItem: "",
      alert: false,
      datacollection: null,
      date: null,
      sensor_date_items: null,
      dateArray: [],
      menu1: false,
      menu2: false,
      dlUrl: null,
      map: "",
      items: null,
      marker_items: [],
      makers: [],
      graphLabels: null,
      graphLabelsMonth: null,
      nowGraphLabel: "気温",
      nowGraphValue: "temp",
      nowGraphValueHash: {
        //area1: { name: "エリア1", unit: "CPS" },
        //area2: { name: "エリア2", unit: "CPS" },
        //area3: { name: "エリア3", unit: "CPS" },
        //area4: { name: "エリア4", unit: "CPS" },
        //area5: { name: "エリア5", unit: "CPS" },
        //area6: { name: "エリア6", unit: "CPS" },
        //differential: { name: "differential", unit: "CPS" },
        temp: { name: "気温", unit: "℃" },
        pressure: { name: "気圧", unit: "hPa" },
        humidity: { name: "湿度", unit: "%" },
        co2: { name: "CO2", unit: "ppm" },
        tvoc: { name: "tvoc", unit: "" },
      },
      btnStatus: false,
      loading: true,
      myLatLng: { lat: 34.6692668, lng: 135.4760877 },
      headers: [
        {
          text: "",
          value: "name",
          align: "start",
          sortable: false,
          class: "action",
          width: "10px",
        },
        // { text: "最新データ", value: "time", align: "start", sortable: false },
        // { text: "lon", value: "longitude", align: "start", sortable: false },
        // { text: "lat", value: "latitude", align: "start", sortable: false },
        // { text: "", value: "info", sortable: false , align: "end", width: "40%"},
        // { text: "", value: "actions", align: "left", sortable: false  , width: "158px", class: "action2"},
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          class: "action2",
        },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            title: {
              display: true,
              text: "sss",
              fontSize: 14,
              fontStyle: "bold",
              position: "bottom",
            },
          },
          x: {
            type: "time",
            time: {
              displayFormats: {
                second: "H:mm:ss",
                minute: "M/D H:mm",
                hour: "M/D H:mm",
                day: "M/D",
                week: "M/D",
                quarter: "MMM YYYY",
              },
            },
          },
        },
        plugins: {
          zoom: {
            zoom: {
              enabled: true,
              drag: true,
              mode: "x",
              //sensitivity: 3,//threshold: 2,//overScaleMode: "y",
              // threshold: 10,
              minRange: {
                x: 10,
              },
              threshold: 5,
              onZoomComplete: this.onZoomComplete,
              onZoomStart: this.onZoomStart,
              onZoom: this.onZoom,
            },
          },
        },
      },
    };
  },
  computed: {
    formatTime: function() {
      let timeStrings = [this.min.toString(), this.sec.toString()].map(function(
        str
      ) {
        if (str.length < 2) {
          return "0" + str;
        } else {
          return str;
        }
      });
      return timeStrings[0] + ":" + timeStrings[1];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    role() {
      return this.$store.getters["auth/role"];
    },
  },
  mounted() {
    this.btnStatus = false;
    let script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBO1dXONjkI0eBXX-GJ_bTxlodSxD_eicI&callback=initMap";
    script.async = true;
    document.head.appendChild(script);

    window.initMap = () => {
      this.map = new window.google.maps.Map(this.$refs.map, {
        center: this.myLatLng,
        zoom: 6,
      });

      this.fetchGpsData();
    };
  },
  methods: {
    redirectByItem(item) {
      this.$router.push("/mo/" + this.$route.params.team_id + "/" + item.id);
    },
    zoomMapByItem(item) {
      var pos = { lat: item.latitude, lng: item.longitude };
      this.map.zoom = 12;
      this.map.panTo(pos);
    },
    loadBtnClicked: function() {
      this.zoomReset();
      this.fetchSensorData();
    },
    onZoom: function(chart) {
      //console.log("### onZoom");
      //console.log(chart);
    },
    onZoomStart: function(chart, event, point) {
      //console.log("### onZoomStart");
      //console.log(chart);
      //console.log(event);
      //console.log(point);
      // if(!event) return false;
    },
    onZoomComplete: function(chart) {
      /*
      console.log("### onZoomComplete");
      console.log(chart); //ZoomAmount
      console.log(chart.scales.x.min);
      console.log(chart.scales.x.max);
      console.log(chart.boxes[2].min);
      console.log(chart.boxes[2].max);
      */
      /*
            this.datacollection = {
        labels: myLabel,
        datasets: datas,
      };
      */
      //console.log(this.datacollection);
      //console.log(chart.zoom);
      //console.log(chart.zoomScale);
      //console.log(chart.getScale);
    },
    count: function() {
      if (this.sec <= 0 && this.min >= 1) {
        this.min--;
        this.sec = 59;
      } else if (this.sec <= 0 && this.min <= 0) {
        this.complete();
      } else {
        this.sec--;
      }
    },
    complete: function() {
      this.min = 1;
      this.sec = 0;
      this.zoomReset();
      this.fetchSensorData();
      //clearInterval(this.timerObj);
    },
    reloadTimer: function(value, event) {
      if (value == "on") {
        let self = this;
        this.timerObj = setInterval(function() {
          self.count();
        }, 1000);
        this.timerOn = true;
      } else if (value == "off") {
        clearInterval(this.timerObj);
        this.timerOn = false;
      }
    },
    zoomReset: function(value, event) {
      this.$refs.graph1.resetZoom();
    },
    graphOnLoaded() {
      this.loading = false;
    },
    openGraph7(item) {
      this.$router.push("/sensorgraph7/" + item.id);
    },
    async fetchSensorDate() {
      this.$axios.get("/sanctum/csrf-cookie");
      this.loading = true;
      console.log("#### fetchSensorDate");
      const response = await this.$axios
        .post("/api/sensor/date_temp", {
          id: this.$route.params.id,
        })
        .catch((err) => err.response || err);

      if (response.status === OK) {
        this.$store.commit("auth/setApiStatus", true);
        this.sensor_date_items = response.data.items;
        this.arrayEvents = response.data.items;
        return false;
      }
      this.$store.commit("auth/setApiStatus", false);
      this.loading = false;
      if (response.status === UNPROCESSABLE_ENTITY) {
        this.$router.commit("auth/setLoginErrorMessages", response.data.errors);
      } else {
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    async fetchGpsData() {
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/sensor/gps_byteam_temp", {
          team_id: this.$route.params.team_id,
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        console.log("### 1");
        this.$store.commit("auth/setApiStatus", true);
        this.marker_items = response.data.items;
        this.setPins();
        console.log("### 2");
        const now = dayjs(); // 現在の日付情報を取得
        this.date = [now.format("YYYY-MM-DD")];
        this.start_date = [now.format("YYYY-MM-DD")];
        this.end_date = [now.add(1, "d").format("YYYY-MM-DD")];
        if (this.$route.params.id) {
          //if (response.data.items[0].id == this.$route.params.id) {
          console.log("### 3");
          this.fetchSensorData();
        } else {
          console.log("### 4");
          //"/to/:team_id/:id",
          this.$router.push(
            "/mo/" +
              this.$route.params.team_id +
              "/" +
              response.data.items[0].id
          );
        }
        return false;
      }
      this.loading = false;
      this.$store.commit("auth/setApiStatus", false);
      if (response.status === UNPROCESSABLE_ENTITY) {
        this.$router.push("/teams/");
        this.$router.commit("auth/setLoginErrorMessages", response.data.errors);
      } else {
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    setNowPinColor() {
      var maker_color = "#333333";
      this.makers.forEach(function(item) {
        //
        // 1時間以内か比較
        this.marker_items.forEach(function(maker_item) {
          //this.setPosGoogleMap2(item);
          if (item.url == maker_item.id) {
            const hour = 1 * 60 * 60 * 1000;
            const now = dayjs();
            const last = dayjs(maker_item.time);
            const diff = now.diff(last);
            const diff2 = diff - hour;
            if (diff2 > 0) {
              maker_color = "#FF0000";
            } else {
              maker_color = "#333333";
            }
          }
        }, this);

        if (this.$route.params.id == item.url) {
          this.map.panTo(item.position);
          this.nowItem = item.title;
          item.setIcon({
            fillColor: "#00FF00", //塗り潰し色
            fillOpacity: 0.6, //塗り潰し透過率
            path: window.google.maps.SymbolPath.CIRCLE, //円を指定
            scale: 16, //円のサイズ
            strokeColor: "#00FF00", //枠の色
            strokeWeight: 1.0, //枠の透過率
          });
        } else {
          item.setIcon({
            fillColor: maker_color, //塗り潰し色
            fillOpacity: 0.6, //塗り潰し透過率
            path: window.google.maps.SymbolPath.CIRCLE, //円を指定
            scale: 14, //円のサイズ
            strokeColor: maker_color, //枠の色
            strokeWeight: 1.0, //枠の透過率
          });
        }
      }, this);
    },
    setPins() {
      this.marker_items.forEach(function(item) {
        this.setPosGoogleMap2(item);
      }, this);
    },
    setPosGoogleMap2(item) {
      var pos = { lat: item.latitude, lng: item.longitude };
      this.map.panTo(pos);
      var marker = new window.google.maps.Marker({
        position: pos,
        map: this.map,
        url: item.id,
        title: item.name,
        icon: {
          fillColor: "#333333", //塗り潰し色
          fillOpacity: 0.6, //塗り潰し透過率
          path: window.google.maps.SymbolPath.CIRCLE, //円を指定
          scale: 16, //円のサイズ
          strokeColor: "#333333", //枠の色
          strokeWeight: 1.0, //枠の透過率
        },
        label: {
          text: item.name, //ラベル文字
          color: "#FFFFFF", //文字の色
          fontSize: "20px", //文字のサイズ
        },
      });
      this.makers.push(marker);
      window.google.maps.event.addListener(
        marker,
        "click",
        (function(url, mythis) {
          return function() {
            //mythis.$router.push("/sensorgraph7/" + url);
            mythis.$router.push(
              "/to/" + mythis.$route.params.team_id + "/" + url
            );
          };
        })(item.id, this)
      );
    },
    // csvDL: function(event) {
    csvDL: function() {
      if (!this.items) {
        this.alert = true;
      } else {
        this.dlUrl =
          // "http://devv.colat.me:8000/api/sensor/csv_dl3/?id=" +
          "/api/sensor/csv_dl3/?id=" +
          this.$route.params.id +
          "&start_datetime=" +
          this.start_date +
          " " +
          this.start_time +
          ":00" +
          "&end_datetime=" +
          this.end_date +
          " " +
          this.end_time +
          ":00";
        console.log(this.dlUrl);
        open(this.dlUrl, "_blank");
      }
    },
    changeGraph: function(value, event) {
      if (!this.items) this.alert = true;
      //this.zoomReset();
      this.nowGraphLabel = event.srcElement.innerText;
      this.nowGraphValue = value;
      this.updateData();
    },
    dateDone1: function(date) {
      this.$refs.menu1.save(date);
      this.start_date = date;
    },
    dateDone2: function(date) {
      this.$refs.menu2.save(date);
      this.end_date = date;
    },

    dateDoneBK: function(date) {
      this.$refs.menu.save(date);
      this.dateArray = date;
      if (!date) return false;
      var start_date = date[0];
      var end_date = date[1] ? date[1] : date[0];

      // 日時を比較して 逆なら直す
      var dateFrom = dayjs(start_date);
      var dateTo = dayjs(end_date);
      if (dateFrom.diff(dateTo) > 0) {
        start_date = date[1];
        end_date = date[0];
      }

      // 日時を比較して期間を調べる
      dateFrom = dayjs(start_date);
      dateTo = dayjs(end_date);
      // console.log(dateTo.diff(dateFrom) / (60 * 60 * 24) / 1000 + 1);
      this.diffDate = dateTo.diff(dateFrom) / (60 * 60 * 24) / 1000 + 1;

      this.fetchSensorData(start_date, end_date);
      this.fetchSensorDate();

      this.dlUrl =
        "http://devv.colat.me:8000/api/sensor/csv_dl3/?id=" +
        // "/api/sensor/csv_dl3/?id=" +
        this.$route.params.id +
        "&start_date=" +
        this.start_date +
        " " +
        this.start_time +
        ":00" +
        "&end_date=" +
        this.end_date +
        " " +
        this.end_time +
        ":00";
    },
    setPosGoogleMap: function(longitude, latitude) {
      var pos = { lat: latitude, lng: longitude };
      this.map.panTo(pos);
      new window.google.maps.Marker({ position: pos, map: this.map });
    },
    // async fetchSensorData(start_date, end_date) {
    async fetchSensorData() {
      console.log("##### fetchSensorData");
      this.loading = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/sensor/data_temp", {
          id: this.$route.params.id,
          start_datetime: this.start_date + " " + this.start_time + ":00",
          end_datetime: this.end_date + " " + this.end_time + ":00",
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.setNowPinColor();
        // this.$store.commit('auth/setApiStatus', true)
        this.items = response.data.items;
        console.log(this.items);
        // this.initData();
        this.updateData();
        return false;
      }
      this.$store.commit("auth/setApiStatus", false);
      if (response.status === UNPROCESSABLE_ENTITY) {
        this.$store.commit("auth/setLoginErrorMessages", response.data.errors);
      } else {
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    initData() {
      var myLabels = this.items.map((item) => item.time);
      var myLabels2 = [];
      var myLabels3 = [];
      myLabels.forEach(function(element) {
        const day1 = dayjs(element);
        myLabels2.push(day1.format("YYYY-MM-DD HH:mm:ss"));
        myLabels3.push(day1.format("YYYY-MM-DD HH:mm:ss"));
      });
      this.graphLabels = myLabels2;
      this.graphLabelsMonth = myLabels3;
      this.updateData();
    },
    updateData() {
      var myDatas = [];
      this.graphLabels = [];
      this.items.forEach(function(item) {
        var hash = {};
        const day1 = dayjs(item["time"]);
        //console.log(a.format('YYYY-MM-DD HH:mm:ss')); // '2019-01-10 17:30:20'
        hash["x"] = day1.format("YYYY-MM-DD HH:mm:ss");
        hash["y"] = item[this.nowGraphValue];
        // this.graphLabels.push(hash["x"]);
        myDatas.push(hash);
        //myDatas[] = { 'x': item['time'], 'y': item[this.nowGraphValue] };
      }, this);

      this.$set(
        this.options.scales.y.title,
        "text",
        this.nowGraphValueHash[this.nowGraphValue].unit
      );

      var datas = [];
      var data = {
        label: this.nowGraphLabel,
        borderColor: "rgba(255,0,0,1)",
        backgroundColor: "rgba(0,0,0,0)",
        borderWidth: 1.5,
        tension: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBorderWidth: 0.0,
        pointHoverRadius: 5,
        pointRadius: 1.5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        data: myDatas,
        // yAxisID: "y-axis-1", // 追加
      };
      datas.push(data);
      //var myLabel = this.graphLabels;
      //if (this.diffDate >= 31) {
      //  myLabel = this.graphLabelsMonth;
      // }
      this.datacollection = {
        // labels: myLabel,
        datasets: datas,
      };
      // this.zoomReset();
    },
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}

.maru {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  background: rgba(0, 255, 0, 0.5);
  font-size: 34px;
  color: white;
  /*margin-left:20px;*/
  line-height: 80px; /*縦中央*/
  text-align: center; /*横中央*/
  border: solid 3px #00ff00;
  margin-left: 15px;
}

.maru_ss {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  background: rgba(0, 255, 0, 0.5);
  font-size: 18px;
  color: white;
  /*margin-left:20px;*/
  line-height: 29px; /*縦中央*/
  text-align: center; /*横中央*/
  /*border: solid 2px rgba(0, 255, 0, 1);*/
  margin-left: 10px;
}
.progress {
  padding-top: 10px;
}

.datecol {
  /*<!--<v-col cols="4" style="background-color: #FF00FF; padding-top: 25px;">-->*/
  /*background-color: #000000;*/
  text-align: left; /*横中央*/
  padding-top: 0px;
  /*padding-left: -10px;
  width: 250px !important;*/
}

.csvdl {
  /*background-color: #ffff00; */
  padding-top: 0px;
}

.btnbox {
  /* background-color: #00FFFF; */
  padding-top: 6px;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}

.mapbox {
  height: 400px;
  margin-left: 10px;
  margin-right: 10px;
  /* background-color: #00FF00; */
}

.chartbox {
  /* background-color: #00FFFF; */
}
.chartAreaWrapper {
  width: 80%;
  overflow-x: scroll;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.time {
  display: inline-block;
  text-align: center;
  font-size: 15px;
  vertical-align: middle;
  /*margin-left:20px;*/
  line-height: 15px; /*縦中央*/
  text-align: center; /*横中央*/
  /*border: solid 1px #00ff00;*/
  margin-right: 6px;
  margin-top: -0px;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .maru {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    background: rgba(0, 255, 0, 0.5);
    font-size: 24px;
    color: white;
    /*margin-left:20px;*/
    line-height: 46px; /*縦中央*/
    text-align: center; /*横中央*/
    border: solid 3px #00ff00;
    margin-left: -10px;
    margin-top: 4px;
  }

  .progress {
    padding-top: 28px;
  }

  .datecol {
    /*<!--<v-col cols="4" style="background-color: #FF00FF; padding-top: 25px;">-->*/
    /*background-color: #ff0000;*/
    text-align: center; /*横中央*/
    padding-top: 12px;
  }

  .csvdl {
    text-align: center; /*横中央*/
    padding-top: 0px;
  }

  .btnbox {
    margin-right: 3px;
    margin-left: 0px;
  }

  .mapbox {
    height: 260px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .chartbox {
    /* background-color: #0000FF; */
    margin-left: -10px;
    margin-right: -10px;
  }
}
</style>
