import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";

Vue.config.devtools = true;
Vue.config.productionTip = false;
//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//axios.defaults.baseURL = "http://devv.colat.me:8000";
axios.defaults.baseURL = '';
axios.defaults.withCredentials = true;

// https://www.hypertextcandy.com/vue-laravel-tutorial-error-handling/
axios.interceptors.response.use(
  (response) => response,
  (error) => error.response || error
);

/*
if (process.env.MIX_APP_BASE) {
  axios.defaults.baseURL = process.env.MIX_APP_BASE
}
*/
//Vue.use(axios)
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
