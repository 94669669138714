import { OK, UNPROCESSABLE_ENTITY } from "../util";

const state = {
  user: null,
  role: null,
  apiStatus: null,
  loginErrorMessages: null,
};

const getters = {
  check: (state) => !!state.user,
  username: (state) => (state.user ? state.user.name : ""),
  role: (state) => (state.user ? state.user.role : 0),
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setApiStatus(state, status) {
    state.apiStatus = status;
  },
  setLoginErrorMessages(state, messages) {
    state.loginErrorMessages = messages;
  },
  setRegisterErrorMessages(state, messages) {
    state.registerErrorMessages = messages;
  },
};

const actions = {
  async login(context, vueins) {
    context.commit("setApiStatus", null);
    //vueins.$axios.get('/sanctum/csrf-cookie').then((response) => {
    vueins.$axios.get("/sanctum/csrf-cookie");
    const response = await vueins.$axios
      .post("/api/login", {
        email: vueins.model.email,
        password: vueins.model.password,
      })
      .catch((err) => err.response || err);
    if (response.status === OK) {
      console.log("### Login");
      console.log(response.data.user);
      context.commit("setApiStatus", true);
      context.commit("setUser", response.data.user);
      vueins.$router.push("/myteams/");
      return false;
    }
    context.commit("setApiStatus", false);
    if (response.status === UNPROCESSABLE_ENTITY) {
      context.commit("setLoginErrorMessages", response.data.errors);
    } else {
      context.commit("error/setCode", response.status, { root: true });
    }
  },
  async logout(context, vueins) {
    context.commit("setUser", null);
    vueins.$axios
      .post("/api/logout")
      .then((response) => {
        if (response.status === OK) {
          context.commit("setUser", null);
          context.commit("setApiStatus", true);
          vueins.$router.push("/login");
          return false;
        }
        context.commit("setApiStatus", false);
        context.commit("error/setCode", response.status, { root: true });
      })
      .catch(function(error) {
        console.error(error);
      });
  },
  async currentUser(context, vueins) {
    context.commit("setApiStatus", null);
    const response = await vueins.$axios
      .post("/api/user")
      .catch((err) => err.response || err);
    const user = response.data.user || null;
    if (response.status === OK) {
      console.log("### USER RET");
      console.log(response.data.user);
      // context.commit("setUser", response.data.user);
      context.commit("setApiStatus", true);
      context.commit("setUser", user);
      return false;
    }
    context.commit("setApiStatus", false);
    context.commit("error/setCode", response.status, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
