<template>
  <v-container>
    <!--入力フォーム-->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container>
          <h2 v-if="isCreate">新規作成</h2>
          <h2 v-if="!isCreate">更新</h2>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!--名前-->
            <v-text-field
              v-model="customer.name"
              :rules="nameRules"
              :counter="20"
              label="名前"
              required
            ></v-text-field>
            <v-select
              v-model="customer.type"
              :items="type"
              :rules="typeRules"
              item-text="label"
              item-value="value"
              label="タイプ"
              dense
              return-object
            ></v-select>
            <v-select
              v-model="customer.status"
              :items="status"
              :rules="statusRules"
              item-text="label"
              item-value="value"
              label="ステータス"
              dense
              return-object
            ></v-select>
            <!--追加ボタン-->
            <v-btn v-if="isCreate" :disabled="!valid" @click="createCustomer">
              追加
            </v-btn>
            <!--更新ボタン-->
            <v-btn v-if="!isCreate" :disabled="!valid" @click="updateCustomer">
              更新
            </v-btn>
            <v-btn @click="clear">クリア</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <v-col cols="12" sm="12" class="" style="text-align:center;" v-if="loading">
      <v-progress-linear
        v-if="loading"
        style="margin-top: 0px;"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>

    <!--ツールバー-->
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>チーム</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="showDialogNew"
        >新規追加</v-btn
      >
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :footer-props="{
        itemsPerPageOptions: [itemsPerPage],
        showFirstLastPage: true,
      }"
      class="elevation-1"
      @update:page="fetchTeams"
    >
      <template v-slot:item.status="{ item }">
        {{ statusName[item.status] }} /
        {{ typeName[item.type] }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="openGraphOne(item)">
          mdi-chart-line
        </v-icon>
        <v-icon class="mr-2" @click="openGraphAll(item)">
          mdi-chart-areaspline
        </v-icon>
        <v-icon class="mr-2" @click="openSensorDetail(item)">
          mdi-animation-outline
        </v-icon>
        <v-icon class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteConfirm(item.id)" v-if="item.sensors_count == 0">
          mdi-delete
        </v-icon>
        <v-icon @click="deleteCannot(item.id)" v-if="item.sensors_count != 0">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <!--削除不可ダイアログを追加-->
    <v-dialog v-model="deleteCannotDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">削除不可</v-card-title>
        <v-card-text
          >ID:{{ deleteID }}は所属センサーがある為削除できません</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteCannotDialog = false"
            >キャンセル</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 削除確認ダイアログを追加 -->
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">削除確認</v-card-title>
        <v-card-text>ID:{{ deleteID }}を削除してもよろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false"
            >キャンセル</v-btn
          >
          <v-btn color="green darken-1" text @click="deleteItem(deleteID)"
            >削除</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { OK, CREATED, UNPROCESSABLE_ENTITY } from '../util'
import { OK, UNPROCESSABLE_ENTITY } from "../util";
export default {
  data() {
    return {
      statusName: {
        "1": "公開",
        "0": "一時停止",
      },
      typeName: {
        "1": "宇宙線",
        "2": "温度",
      },
      status: [
        { label: "公開", value: "1" },
        { label: "一時停止", value: "0" },
      ],
      type: [
        { label: "宇宙線", value: "1" },
        { label: "温度", value: "2" },
      ],
      loading: false,
      headers: [
        { text: "ID", value: "id", align: "start", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Sonsors", value: "sensors_count", sortable: false },
        { text: "Users", value: "users_count", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      page: 1,
      itemsPerPage: 10,
      total: 0,
      items: [],

      deleteDialog: false, // 追加：初期値は非表示
      deleteCannotDialog: false, // 追加：初期値は非表示
      deleteID: null, // 追加：削除Itemのid

      // フォーム入力値
      customer: {
        id: "",
        name: "",
        role: "10",
        type: "1",
      },
      // バリデーション
      valid: true,
      nameRules: [
        (v) => !!v || "名前は必須項目です",
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      statusRules: [(v) => !!v || "ステータスは必須項目です"],
      typeRules: [(v) => !!v || "タイプは必須項目です"],
      // ローディングの表示フラグ
      progress: false,
      // ダイアログの表示フラグ
      dialog: false,
      // 新規・更新のフラグ
      isCreate: true,
    };
  },
  mounted() {
    // csrf対策
    // nginxでRPする場合は/sanctumがapi側を見に行くようにしてください
    //this.$axios.get('/sanctum/csrf-cookie');
  },
  methods: {
    openGraphOne(item) {
      if (item.type == 1) {
        this.$router.push("/to/" + item.id);
      } else if (item.type == 2) {
        this.$router.push("/mo/" + item.id);
      } else if (item.type == 3) {
        this.$router.push("/no/" + item.id);
      }
      //this.$router.push("/to/" + item.id);
    },
    openGraphAll(item) {
      //this.$router.push("/ta/" + item.id);
      if (item.type == 1) {
        this.$router.push("/ta/" + item.id);
      } else if (item.type == 2) {
        this.$router.push("/ma/" + item.id);
      } else if (item.type == 3) {
        this.$router.push("/na/" + item.id);
      }
    },
    openSensorDetail(item) {
      this.$router.push("/team/" + item.id);
    },
    deleteConfirm(id) {
      this.deleteDialog = true;
      this.deleteID = id;
    },
    deleteCannot(id) {
      this.deleteCannotDialog = true;
      this.deleteID = id;
    },
    deleteItem(id) {
      this.$axios
        .delete("/api/teams/" + id)
        .then((response) => {
          if (response.status === OK) {
            this.fetchTeams();
            return false;
          }
          this.$store.commit("error/setCode", response.status, { root: true });
        })
        .catch(function(error) {
          console.error(error);
        });
      this.deleteDialog = false;
    },
    editItem(item) {
      this.customer.id = item.id;
      this.customer.name = item.name;
      this.customer.type = String(item.type);
      this.customer.status = String(item.status);
      this.isCreate = false;
      this.dialog = true;
    },
    async loginCheck() {
      await this.$store.dispatch("auth/currentUser", this);
      //await store.dispatch('auth/currentUser', this)
      // トップページに移動する
      // this.$router.push('/');
    },
    async createCustomer() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const type = this.customer.type.value
        ? this.customer.type.value
        : this.customer.type;
      const status = this.customer.status.value
        ? this.customer.status.value
        : this.customer.status;
      const response = await this.$axios
        .post("/api/teams", {
          name: this.customer.name,
          type: type,
          status: status,
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.dialog = false;
        this.progress = false;
        await this.fetchTeams();
        return false;
      }
      this.progress = false;
      if (response.status === UNPROCESSABLE_ENTITY) {
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        this.dialog = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    async updateCustomer() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const type = this.customer.type.value
        ? this.customer.type.value
        : this.customer.type;
      const status = this.customer.status.value
        ? this.customer.status.value
        : this.customer.status;
      const response = await this.$axios
        .put(`/api/teams/${this.customer.id}`, {
          name: this.customer.name,
          type: type,
          status: status,
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.dialog = false;
        this.progress = false;
        await this.fetchTeams();
        return false;
      }
      this.progress = false;
      if (response.status === UNPROCESSABLE_ENTITY) {
        // console.log(response.data.errors);
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        this.dialog = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    // --------------------------------
    // フォームのクリア
    // --------------------------------
    clear: function() {
      this.$refs.form.reset();
    },
    // --------------------------------
    // ダイアログの表示
    // --------------------------------
    showDialogNew: function() {
      this.isCreate = true;
      this.dialog = true;
      this.clear(); // MUST this.dialog = true;
    },
    showDialogUpdate: function(id, name, gender) {
      this.customer.id = id;
      this.customer.name = name;
      this.customer.gender = gender;
      this.isCreate = false;
      this.dialog = true;
    },
    deleteCustomer: function(name, id) {
      if (!confirm(name + id + "を削除してもよろしいですか？")) {
        return;
      }
    },
    async fetchTeams() {
      this.loading = true;
      const response = await this.$axios.get(`/api/teams/?page=${this.page}`);
      this.loading = false;
      if (response.status !== OK) {
        this.$store.commit("error/setCode", response.status);
        return false;
      }
      this.items = response.data.items;
      this.currentPage = response.data.current_page;
      this.lastPage = response.data.last_page;
    },
  },
  beforeCreate() {
    // console.log("### Sensors beforeCreate ");
  },
  created() {
    // console.log("### Sensors Created ");
    this.loginCheck();
  },
  watch: {
    $route: {
      async handler() {
        await this.fetchTeams();
      },
      immediate: true,
    },
  },
};
</script>
