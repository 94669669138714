    <template>
    <v-footer>
      <span>&copy; {{ new Date().getFullYear() }} TAC</span>
    </v-footer>
    </template>

    <script>
    //import { mapState, mapGetters } from 'vuex'
    export default {
        /*
        data () {
            return {
                fixed: false,
            }
        }
*/
        /*
        computed: {
            ...mapState({
                apiStatus: state => state.auth.apiStatus
                }),
            ...mapGetters({
                isLogin: 'auth/check'
                })
        },
        methods: {
            async logout () {
                await this.$store.dispatch('auth/logout')
                if (this.apiStatus) {
                    this.$router.push('/login')
                }
            }
        }
        */
    }
    </script>