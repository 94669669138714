import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Sensors from "../views/Sensors.vue";
//import SensorsUpdate from '../views/SensorsUpdate.vue'
//import SensorGraph from '../views/SensorGraph.vue'
//import SensorGraph2 from '../views/SensorGraph2.vue'
//import SensorGraph3 from '../views/SensorGraph3.vue'
//import SensorGraph4 from '../views/SensorGraph4.vue'
//import SensorGraph5 from "../views/SensorGraph5.vue";
//import SensorGraph6 from "../views/SensorGraph6.vue";
//import SensorGraph7 from "../views/SensorGraph7.vue";
// import SensorGraph8 from "../views/SensorGraph8.vue";
//import SensorsMap from '../views/SensorsMap.vue'

import SystemError from "../views/errors/System.vue";
import NotFound from "../views/errors/NotFound.vue";

import Users from "../views/Users.vue";
import Teams from "../views/Teams.vue";
import TeamDetail from "../views/TeamDetail.vue";
import store from "../store";

import GraphAll from "../views/GraphAll.vue";
import GraphOne from "../views/GraphOne.vue";

import MyTeams from "../views/MyTeams.vue";
import MyTeamDetail from "../views/MyTeamDetail.vue";

import GraphOneTemp from "../views/GraphOneTemp.vue";
import GraphAllTemp from "../views/GraphAllTemp.vue";

import GraphOneNt from "../views/GraphOneNt.vue";
import GraphAllNt from "../views/GraphAllNt.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/myteams",
    name: "MyTeams",
    component: MyTeams,
  },
  {
    path: "/myteam/:team_id",
    name: "MyTeamDetail",
    component: MyTeamDetail,
  },
  {
    path: "/ta/:team_id",
    name: "GraphAll",
    component: GraphAll,
  },
  {
    path: "/to/:team_id/:id",
    name: "GraphOne",
    component: GraphOne,
  },
  {
    path: "/to/:team_id",
    name: "GraphOne",
    component: GraphOne,
  },
  {
    path: "/mo/:team_id/:id",
    name: "GraphOneTemp",
    component: GraphOneTemp,
  },
  {
    path: "/mo/:team_id",
    name: "GraphOneTemp",
    component: GraphOneTemp,
  },
  {
    path: "/ma/:team_id",
    name: "GraphAllTemp",
    component: GraphAllTemp,
  },
  {
    path: "/no/:team_id/:id",
    name: "GraphOneNt",
    component: GraphOneNt,
  },
  {
    path: "/no/:team_id",
    name: "GraphOneNt",
    component: GraphOneNt,
  },
  {
    path: "/na/:team_id",
    name: "GraphAllNt",
    component: GraphAllNt,
  },
  /*
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter(to, from, next) {
      console.log(to);
      console.log(from);
      if (store.getters["auth/username"]) {
        next({ path: "/sensorgraph8" });
      } else {
        next({ path: "/login" });
      }
    },
  },*/
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter(to, from, next) {
      console.log("### Login 1 ###");
      if (store.getters["auth/check"]) {
        // next("/myteams");
        console.log("### Login 2 ###");
        next({ path: "/myteams" });
      } else {
        console.log("### Login 3 ###");
        next();
      }
    },
  },

  {
    path: "/sensors",
    name: "Sensors",
    component: Sensors,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/team/:team_id",
    name: "TeamDetail",
    component: TeamDetail,
  },
  /*
  {
    path: '/sensors/update',
    name: 'SensorsUpdate',
    component: SensorsUpdate
  },
  {
    path: '/sensorgraph/:id',
    name: 'SensorGraph',
    component: SensorGraph
  },
  {
    path: '/sensorgraph2/:id',
    name: 'SensorGraph2',
    component: SensorGraph2
  },
  {
    path: '/sensorgraph3/:id',
    name: 'SensorGraph3',
    component: SensorGraph3
  },
  {
    path: '/sensorgraph4/:id',
    name: 'SensorGraph4',
    component: SensorGraph4
  },
  */
  /*
  {
    path: "/sensorgraph5/:id",
    name: "SensorGraph5",
    component: SensorGraph5,
    beforeEnter(to, from, next) {
      console.log(to);
      console.log(from);
      if (store.getters["auth/username"]) {
        next();
      } else {
        next({ path: "/login" });
      }
    },
  },
  {
    path: "/sensorgraph5/",
    name: "SensorGraph5",
    component: SensorGraph5,
    beforeEnter(to, from, next) {
      console.log(to);
      console.log(from);
      if (store.getters["auth/username"]) {
        next();
      } else {
        next({ path: "/login" });
      }
    },
  },
  */
  /*
  {
    path: "/sensorgraph6/",
    name: "SensorGraph6",
    component: SensorGraph6,
    beforeEnter(to, from, next) {
      console.log(to);
      console.log(from);
      if (store.getters["auth/username"]) {
        next();
      } else {
        next({ path: "/login" });
      }
    },
  },
  {
    path: "/sensorgraph7/:id",
    name: "SensorGraph7",
    component: SensorGraph7,
    beforeEnter(to, from, next) {
      console.log(to);
      console.log(from);
      if (store.getters["auth/username"]) {
        next();
      } else {
        next({ path: "/login" });
      }
    },
  },
  {
    path: "/sensorgraph7/",
    name: "SensorGraph7",
    component: SensorGraph7,
    beforeEnter(to, from, next) {
      console.log(to);
      console.log(from);
      if (store.getters["auth/username"]) {
        next();
      } else {
        next({ path: "/login" });
      }
    },
  },
  */
  /*
  {
    path: "/sensorgraph8/",
    name: "SensorGraph8",
    component: SensorGraph8,
    beforeEnter(to, from, next) {
      console.log(to);
      console.log(from);
      if (store.getters["auth/username"]) {
        next();
      } else {
        next({ path: "/login" });
      }
    },
  },
  */
  /*
  {
    path: '/sensorsmap',
    name: 'SensorsMap',
    component: SensorsMap
  }, 
  */
  {
    path: "/500",
    component: SystemError,
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
