<script>
import { Line, mixins } from "vue-chartjs";
import zoom from "chartjs-plugin-zoom";
import 'chartjs-adapter-moment';

//mixins.register(zoom)
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options"],
  methods: {
    resetZoom() {
      this.$data._chart.resetZoom()
    },
  },
  mounted() {
    this.addPlugin(zoom);
    //this.$data._chart
    //this.$refs.canvas
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    // this.renderChart(this.chartData, this.options)
  },
};
</script>

<style></style>
