<template>
  <v-container>
    <!--入力フォーム-->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container>
          <h2 v-if="isCreate">新規作成</h2>
          <h2 v-if="!isCreate">更新</h2>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!--名前-->
            <v-text-field
              v-model="customer.title"
              :rules="nameRules"
              :counter="20"
              label="名前"
              required
            ></v-text-field>
            <!--名前-->
            <v-text-field
              v-model="customer.sensor_name"
              :rules="nameSearchRules"
              :counter="20"
              label="検索名"
              required
            ></v-text-field>
            <!--追加ボタン-->
            <v-btn v-if="isCreate" :disabled="!valid" @click="createCustomer">
              追加
            </v-btn>
            <!--更新ボタン-->
            <v-btn v-if="!isCreate" :disabled="!valid" @click="updateCustomer">
              更新
            </v-btn>
            <v-btn @click="clear">クリア</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <!--ツールバー-->
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>センサー</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="showDialogNew"
        >新規追加</v-btn
      >
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :footer-props="{
        itemsPerPageOptions: [itemsPerPage],
        showFirstLastPage: true,
      }"
      class="elevation-1"
      @update:page="fetchSensors"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="openGraph(item)">
          mdi-triangle-wave
        </v-icon>
        <v-icon class="mr-2" @click="openGraph2(item)">
          mdi-triangle-wave
        </v-icon>
        <v-icon class="mr-2" @click="openGraph3(item)">
          mdi-triangle-wave
        </v-icon>
        <v-icon class="mr-2" @click="openGraph4(item)">
          mdi-triangle-wave
        </v-icon>
        <v-icon class="mr-2" @click="openGraph5(item)">
          mdi-triangle-wave
        </v-icon>
        <v-icon class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteConfirm(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <!-- 削除確認ダイアログを追加 -->
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">削除確認</v-card-title>
        <v-card-text>ID:{{ deleteID }}を削除してもよろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false"
            >キャンセル</v-btn
          >
          <v-btn color="green darken-1" text @click="deleteItem(deleteID)"
            >削除</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { OK, CREATED, UNPROCESSABLE_ENTITY } from '../util'
import { OK, UNPROCESSABLE_ENTITY } from "../util";
export default {
  data() {
    return {
      loading: false,
      model: {
        email: "",
        password: "",
      },

      //sensors: [],

      headers: [
        { text: "ID", value: "id", align: "start", sortable: false },
        { text: "Title", value: "title", sortable: false },
        { text: "sensorName", value: "sensor_name", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      page: 1,
      itemsPerPage: 10,
      total: 0,
      items: [],

      deleteDialog: false, // 追加：初期値は非表示
      deleteID: null, // 追加：削除Itemのid

      /*
      currentPage: 0,
      lastPage: 0,
      // データテーブル
      headers: [
        { text: '操作', align: 'center', sortable: false },
        { text: "追加日", value: "created_at" },
        { text: "名前", value: "title" },
        { text: "検索名", value: "sensor_name" },
 
      ],
      pagination: {
        descending: true,
        rowsPerPage: 10,
      },
*/
      // フォーム入力値
      customer: {
        id: "",
        title: "",
        sensor_name: "",
      },
      // バリデーション
      valid: true,
      nameRules: [
        (v) => !!v || "名前は必須項目です",
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      nameSearchRules: [
        (v) => !!v || "検索名は必須項目です",
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      // ローディングの表示フラグ
      progress: false,
      // ダイアログの表示フラグ
      dialog: false,
      // 新規・更新のフラグ
      isCreate: true,
    };
  },
  mounted() {
    // csrf対策
    // nginxでRPする場合は/sanctumがapi側を見に行くようにしてください
    //this.$axios.get('/sanctum/csrf-cookie');
    console.log("### Sensors Mounted ");
  },
  methods: {
    deleteConfirm(id) {
      console.log("### deleteConfirm");
      this.deleteDialog = true;
      this.deleteID = id;
    },
    deleteItem(id) {
      console.log("### deleteItem");
      console.log(id);
      this.$axios
        .delete("/api/sensors/" + id)
        .then((response) => {
          console.log("### 3 ");
          console.log(response.data);
          if (response.status === OK) {
            console.log("### 4 ");
            this.fetchSensors();
            return false;
          }
          console.log("### 5 ");
          //context.commit('setApiStatus', false)
          //context.commit('error/setCode', response.status, { root: true })
        })
        .catch(function(error) {
          console.losg("### 6 ");
          console.error(error);
        });
      this.deleteDialog = false;
    },
    openGraph(item) {
      console.log("### openGraph");
      console.log(item);
      this.$router.push("/sensorgraph/" + item.id);
    },
    openGraph2(item) {
      console.log("### openGraph2");
      console.log(item);
      this.$router.push("/sensorgraph2/" + item.id);
    },
    openGraph3(item) {
      console.log("### openGraph3");
      console.log(item);
      this.$router.push("/sensorgraph3/" + item.id);
    },
    openGraph4(item) {
      console.log("### openGraph4");
      console.log(item);
      this.$router.push("/sensorgraph4/" + item.id);
    },
    openGraph5(item) {
      console.log("### openGraph5");
      console.log(item);
      this.$router.push("/sensorgraph5/" + item.id);
    },
    editItem(item) {
      console.log("### editItem");
      console.log(item);
      console.log(item.id);
      console.log(item.title);
      console.log(item.sensor_name);

      this.customer.id = item.id;
      this.customer.title = item.title;
      this.customer.sensor_name = item.sensor_name;
      this.isCreate = false;
      this.dialog = true;

      /*
      this.customer.id = item.id;
      this.customer.name = name;
      this.customer.gender = gender;
      this.isCreate = false;
      this.dialog = true;
      */
      //this.editedIndex = this.desserts.indexOf(item)
      //this.editedItem = Object.assign({}, item)
      //this.dialog = true
    },
    async loginCheck() {
      console.log("### loginCheck");
      await this.$store.dispatch("auth/currentUser", this);
      //await store.dispatch('auth/currentUser', this)
      // トップページに移動する
      // this.$router.push('/');
    },
    async createCustomer() {
      console.log("### createCustomer");
      this.progress = true;

      this.$axios.get("/sanctum/csrf-cookie");
      console.log("### createCustomer 2");
      const response = await this.$axios
        .post("/api/sensors", {
          title: this.customer.title,
          sensor_name: this.customer.sensor_name,
        })
        .catch((err) => err.response || err);

      if (response.status === OK) {
        console.log("### createCustomer 4");
        // context.commit("setApiStatus", true);
        // context.commit("setUser", response.data);
        this.dialog = false;
        this.progress = false;
        await this.fetchSensors();
        return false;
      }
      // context.commit("setApiStatus", false);
      console.log("### createCustomer 5");
      if (response.status === UNPROCESSABLE_ENTITY) {
        console.log("### createCustomer 6");
        console.log(response.data.errors);
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        console.log("### updateCustomer 7");
        // this.dialog = false
        // this.progress = false
        // context.commit("error/setCode", response.status, { root: true });
      }
      console.log(response);

      // ----------------------
      /*
      console.log("### Create");
      this.progress = true;

      this.$axios.get("/sanctum/csrf-cookie");
      console.log("### LOGIN 2");
      const response = await this.$axios
        .post("/api/sensors", {
          title: this.customer.title,
          sensor_name: this.customer.sensor_name,
        })
        .catch((err) => err.response || err);
      console.log(response);
      */

      /*
      this.$axios.get("/sanctum/csrf-cookie").then((response) => {
        console.log(response.data);
        console.log(this.customer.title);
        console.log(this.customer.sensor_name);
        this.$axios
          .post("/api/sensors", {
            title: this.customer.title,
            sensor_name: this.customer.sensor_name,
          })
          .then((response) => {
            console.log("### 3 ");
            console.log(response.data);
            if (response.status === OK) {
              //context.commit('setApiStatus', true)
              console.log("### 4 ");
              this.dialog = false
              this.progress = false
              //vueins.$router.push('/login');
              return false;
            }
            console.log("### 5 ");
            //context.commit('setApiStatus', false)
            //context.commit('error/setCode', response.status, { root: true })
          })
          .catch(function(error) {
            console.log("### 6 ");
            this.progress = false
            console.error(error);
          });
      });
      */
    },
    async updateCustomer() {
      console.log("### updateCustomer 1");
      this.progress = true;

      this.$axios.get("/sanctum/csrf-cookie");
      console.log("### updateCustomer 2");
      const response = await this.$axios
        .put(`/api/sensors/${this.customer.id}`, {
          // id: this.customer.id,
          title: this.customer.title,
          sensor_name: this.customer.sensor_name,
        })
        .catch((err) => err.response || err);

      if (response.status === OK) {
        console.log("### updateCustomer 4");
        // context.commit("setApiStatus", true);
        // context.commit("setUser", response.data);
        this.dialog = false;
        this.progress = false;
        await this.fetchSensors();
        return false;
      }
      // context.commit("setApiStatus", false);
      console.log("### updateCustomer 5");
      if (response.status === UNPROCESSABLE_ENTITY) {
        console.log("### updateCustomer 6");
        console.log(response.data.errors);
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        console.log("### updateCustomer 7");
        this.dialog = false;
        this.progress = false;
        // context.commit("error/setCode", response.status, { root: true });
      }
      console.log(response);
    },
    // --------------------------------
    // フォームのクリア
    // --------------------------------
    clear: function() {
      this.$refs.form.reset();
    },
    // --------------------------------
    // 新規追加ダイアログの表示
    // --------------------------------
    showDialogNew: function() {
      this.isCreate = true;
      this.dialog = true;
      this.clear(); // MUST this.dialog = true;
    },
    // --------------------------------
    // 更新ダイアログの表示
    // --------------------------------
    showDialogUpdate: function(id, name, gender) {
      this.customer.id = id;
      this.customer.name = name;
      this.customer.gender = gender;
      this.isCreate = false;
      this.dialog = true;
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteCustomer: function(name, id) {
      if (!confirm(name + id + "さんを削除してもよろしいですか？")) {
        return;
      }
    },
    async fetchSensors() {
      console.log("### fetchSensors 1");
      const response = await this.$axios.get(`/api/sensors/?page=${this.page}`);
      console.log("### fetchSensors 2");
      if (response.status !== OK) {
        console.log("### fetchSensors 3");
        this.$store.commit("error/setCode", response.status);
        return false;
      }
      console.log("### fetchSensors 4");
      console.log(response.data);

      // this.sensors = response.data.data
      this.items = response.data.data;
      this.currentPage = response.data.current_page;
      this.lastPage = response.data.last_page;
    },
  },
  beforeCreate() {
    console.log("### Sensors beforeCreate ");
  },
  created() {
    console.log("### Sensors Created ");
    this.loginCheck();
  },

  watch: {
    $route: {
      async handler() {
        await this.fetchSensors();
      },
      immediate: true,
    },
  },
};
</script>
