<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="6" sm="3" class="datecol">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="開始日を選択"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            :events="arrayEvents"
            event-color="green lighten-1"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu1 = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="dateDone1(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" sm="2">
        <vue-timepicker
          manual-input
          input-width="100px"
          style="margin-top:0px"
          v-model="start_time"
          hide-clear-button
        ></vue-timepicker>
      </v-col>

      <v-col cols="6" sm="3" class="datecol">
        <!--<v-col cols="4" style="background-color: #FF00FF; padding-top: 25px;">-->
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="終了日を選択"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              width="500px"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            :events="arrayEvents"
            event-color="green lighten-1"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="dateDone2(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="6" sm="2">
        <vue-timepicker
          manual-input
          input-width="100px"
          style="margin-top:0px"
          v-model="end_time"
          hide-clear-button
        ></vue-timepicker>
      </v-col>
      <v-col cols="12" sm="2" class="csvdl" style="text-align:center;">
        <v-progress-linear
          v-if="loading"
          style="margin-top: 0px;"
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
      <v-col cols="12" class="btnbox" style="" v-if="graphEnable">
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area1', $event)"
          >エリア1</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area2', $event)"
          >エリア2</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area3', $event)"
          >エリア3</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area4', $event)"
          >エリア4</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area5', $event)"
          >エリア5</v-btn
        >
        <v-btn
          rounded
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('area6', $event)"
          >エリア6</v-btn
        >
        <v-btn
          rounded
          x-small
          color="warning"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('differential', $event)"
          >differential</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('temp', $event)"
          >気温</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('pressure', $event)"
          >気圧</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('humidity', $event)"
          >湿度</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('co2', $event)"
          >CO2</v-btn
        >
        <v-btn
          rounded
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="changeGraph('lux', $event)"
          >lux</v-btn
        >
      </v-col>


      <v-col cols="12" sm="12" v-show="graphEnable">
        <line-chart
          class="chartbox"
          ref="graph1"
          :chart-data="datacollection"
          :options="options"
          :height="480"
          v-on:chart:update="graphOnLoaded"
          v-on:chart:render="graphOnLoaded"
        ></line-chart>
        <div
          style="padding-top: 0px; margin-right: 8px; margin-bottom: 2px; font-weight: normal; color:#555555; font-size: 12px;"
        >
          {{ nowGraphValueHash[nowGraphValue].name }}
        </div>
    </v-col>
    
    <v-col cols="2" md="2" class="text-left" v-show="graphEnable"> </v-col>
      <v-col cols="2" md="2" class="text-right" v-show="graphEnable">
        <v-select
          v-model="sitemsValue"
          :items="sitems"
          label="avg rate"
          dense
          return-object
          @change="changeSelectValue"
        ></v-select>
      </v-col>
    <v-col cols="8" sm="8" v-show="graphEnable">
        <span class="time">{{ formatTime }}</span>
        <v-btn
          v-if="!timerOn"
          outlined
          x-small
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="reloadTimer('on', $event)"
          >Auto Reload ON</v-btn
        >
        <v-btn
          v-if="timerOn"
          x-small
          color="success"
          dark
          style="margin-right: 3px;"
          @click="reloadTimer('off', $event)"
          >Auto Reload OFF</v-btn
        >
        <v-btn
          outlined
          x-small
          color="primary"
          dark
          style="margin-right: 3px; "
          @click="zoomReset('zoom', $event)"
          >Zoom Reset</v-btn
        >
      </v-col>

      <v-col
        cols="12"
        sm="8"
        style="background-color: #FFFFFF; padding-top: 0px; padding-bottom: 0px; text-align:left;"
      >
        <span v-for="(marker, key, index) in marker_selected" :key="index">
          <!--<div class="maru_s" style="background-color: #00FF00; border:dashed 2px #ff0000;"><span>{{ maker.title }}</span></div>-->
          <div
            class="maru_s"
            :style="{
              'background-color': colors[marker_selected_order[marker.url]],
            }"
          >
            <span>{{ marker.title }}</span>
          </div>
        </span>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        style="background-color: #FFFFFF; padding-top: 0px; padding-bottom: 0px; text-align:right;"
      >
        <v-btn
          x-small
          color="red"
          dark
          style="margin-right: 7px; margin-top: 14px;"
          @click="resetMarkerSelected('Reset', $event)"
          >Reset</v-btn
        >

        <v-btn
          color="primary"
          dark
          style="margin-right: 3px;"
          @click="fetchDataMarkerSelected('load', $event)"
          >Load</v-btn
        >
      </v-col>
      <v-col cols="12" sm="9" lg="9">
        <div ref="map" class="mapbox" style=""></div>
      </v-col>
      <v-col cols="12" sm="3" lg="3" style="background-color: #FFFFFF;">
        <v-data-table :headers="headers" :items="marker_items" class="sm">
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2"
              color="green"
              dense
              v-if="item.longitude && item.gps_status != 0"
            >
              mdi-compass
            </v-icon>
            <v-icon class="mr-2" color="red" dense v-else>
              mdi-compass-off
            </v-icon>
            <v-icon class="mr-2" color="red" dense v-if="item.time_diff.hours">
              mdi-antenna
            </v-icon>
            <v-icon class="mr-2" color="green" dense v-else>
              mdi-antenna
            </v-icon>
            <v-icon
              class="mr-2"
              @click="zoomMapByItem(item)"
              v-if="item.longitude && item.gps_status != 0"
            >
              mdi-magnify
            </v-icon>
            <v-icon class="mr-2" color="red" v-else>
              mdi-magnify
            </v-icon>
            <v-icon class="mr-2" @click="addSelectedByItem(item)">
              mdi-loupe
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { OK, UNPROCESSABLE_ENTITY } from "../util";
import LineChart from "../components/Chart";
import dayjs from "dayjs";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    LineChart,
    VueTimepicker,
  },
  watch: {
    $route: {
      async handler() {
        if (this.date && this.$route.params.id) this.dateDone(this.date);
        // await this.fetchSensors();
      },
      immediate: true,
    },
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
  },
  data() {
    return {
      sitemsValue: 0,
      sitems: [0, 3, 6 , 10, 30, 60, 120, 180, 300, 3600],
      min: 2,
      sec: 0,
      timerObj: null,
      timerOn: false,
      colors: [
        "#e4002b",
        "#009f4d",
        "#00205b",
        "#a51890",
        "#fe5000",
        "#ffc845",
        "#7d3f98",
        "#051736",
        "#1cb0f6",
        "#6d2d41",
        "#005670",
      ],
      start_time: "00:00",
      end_time: "00:00",
      start_date: "",
      end_date: "",
      graphEnable: false,
      diffDate: 0,
      arrayEvents: [],
      nowItem: "",
      alert: false,
      datacollection: null,
      date: null,
      sensor_date_items: null,
      dateArray: [],
      menu: false,
      dlUrl: null,
      map: "",
      items: [],
      marker_items: [],
      marker_selected: {},
      marker_selected_data_items: {},
      marker_selected_order: {},
      makers: [],
      graphLabels: null,
      graphLabelsMonth: null,
      nowGraphLabel: "エリア1",
      nowGraphValue: "area1",
      nowGraphValueHash: {
        area1: { name: "エリア1", unit: "CPS" },
        area2: { name: "エリア2", unit: "CPS" },
        area3: { name: "エリア3", unit: "CPS" },
        area4: { name: "エリア4", unit: "CPS" },
        area5: { name: "エリア5", unit: "CPS" },
        area6: { name: "エリア6", unit: "CPS" },
        differential: { name: "differential", unit: "CPS" },
        temp: { name: "気温", unit: "℃" },
        pressure: { name: "気圧", unit: "hPa" },
        humidity: { name: "湿度", unit: "%" },
        co2: { name: "CO2", unit: "ppm" },
        lux: { name: "lux", unit: "lx" },
      },
      btnStatus: false,
      loading: false,
      myLatLng: { lat: 34.6692668, lng: 135.4760877 },
      headers: [
        {
          text: "",
          value: "name",
          align: "start",
          sortable: false,
          class: "action",
          width: "10px",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          class: "action2",
        },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            title: {
              display: true,
              text: "",
              fontSize: 14,
              fontStyle: "bold",
              position: "bottom",
            },
          },
          x: {
            type: "time",
            time: {
              displayFormats: {
                second: "H:mm:ss",
                minute: "M/D H:mm",
                hour: "M/D H:mm",
                day: "M/D",
                week: "M/D",
                quarter: "MMM YYYY",
              },
            },
          },
        },
        plugins: {
          zoom: {
            zoom: {
              enabled: true,
              drag: true,
              mode: "x",
              //sensitivity: 3,//threshold: 2,//overScaleMode: "y",
              // threshold: 10,
              minRange: {
                x: 10,
              },
              threshold: 5,
              onZoomComplete: this.onZoomComplete,
              onZoomStart: this.onZoomStart,
              onZoom: this.onZoom,
            },
          },
        },
      },
    };
  },
  computed: {
    formatTime: function() {
      let timeStrings = [this.min.toString(), this.sec.toString()].map(function(
        str
      ) {
        if (str.length < 2) {
          return "0" + str;
        } else {
          return str;
        }
      });
      return timeStrings[0] + ":" + timeStrings[1];
    },
  },
  mounted() {
    this.btnStatus = false;
    let script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBO1dXONjkI0eBXX-GJ_bTxlodSxD_eicI&callback=initMap";
    script.async = true;
    document.head.appendChild(script);

    window.initMap = () => {
      this.map = new window.google.maps.Map(this.$refs.map, {
        center: this.myLatLng,
        zoom: 6,
      });

      this.fetchGpsData();
    };
  },
  methods: {
    changeSelectValue(val) {
      this.sitemsValue = val;
      this.updateData();
    },
    count: function() {
      if (this.sec <= 0 && this.min >= 1) {
        this.min--;
        this.sec = 59;
      } else if (this.sec <= 0 && this.min <= 0) {
        this.complete();
      } else {
        this.sec--;
      }
    },
    complete: function() {
      this.min = 2;
      this.sec = 0;
      // this.zoomReset();
      // this.fetchSensorData();
      this.fetchDataMarkerSelected();
      //clearInterval(this.timerObj);
    },
    reloadTimer: function(value, event) {
      if (value == "on") {
        let self = this;
        this.timerObj = setInterval(function() {
          self.count();
        }, 1000);
        this.timerOn = true;
      } else if (value == "off") {
        clearInterval(this.timerObj);
        this.timerOn = false;
      }
    },
    zoomMapByItem(item) {
      var pos = { lat: item.latitude, lng: item.longitude };
      this.map.zoom = 12;
      this.map.panTo(pos);
    },
    addSelectedByItem(item) {
      //1. すでに登録されているか？
      var selected = false;
      Object.keys(this.marker_selected).forEach(function(key) {
        if (this.marker_selected[key].url == item.id) {
          selected = true;
        }
      }, this);

      if (selected) return;

      //1. makerを取り出す
      this.makers.forEach(function(marker) {
        if (marker.url == item.id) {
          const length = Object.keys(this.marker_selected).length;
          this.marker_selected_order[item.id] = length;
          this.$set(this.marker_selected, marker.url, marker);

          // 色を更新
          this.setSelectedPinColor();
        } else {
          // console.log("##### NO");
        }
      }, this);
    },

    getConversionRgba(color_code, alpha = 1) {
      var rgba_code = [];

      rgba_code["red"] = parseInt(color_code.substring(1, 3), 16);
      rgba_code["green"] = parseInt(color_code.substring(3, 5), 16);
      rgba_code["blue"] = parseInt(color_code.substring(5, 7), 16);
      rgba_code["alpha"] = alpha;
      rgba_code["full"] = Object.values(rgba_code).join(",");

      return rgba_code;
    },
    dateDone1: function(date) {
      this.$refs.menu1.save(date);
      this.start_date = date;
    },
    dateDone2: function(date) {
      this.$refs.menu2.save(date);
      this.end_date = date;
    },
    dateDoneBK: function(date) {
      this.$refs.menu.save(date);
      this.dateArray = date;
      if (!date) return false;
      this.start_date = date[0];
      this.end_date = date[1] ? date[1] : date[0];
      // 日時を比較して 逆なら直す
      var dateFrom = dayjs(this.start_date);
      var dateTo = dayjs(this.end_date);
      if (dateFrom.diff(dateTo) > 0) {
        this.start_date = date[1];
        this.end_date = date[0];
      }
      // 日時を比較して期間を調べる
      dateFrom = dayjs(this.start_date);
      dateTo = dayjs(this.end_date);
      // console.log(dateTo.diff(dateFrom) / (60 * 60 * 24) / 1000 + 1);
      this.diffDate = dateTo.diff(dateFrom) / (60 * 60 * 24) / 1000 + 1;

      // カレンダーを選択したら、データ取得を実行
      this.fetchDataMarkerSelected();
    },
    resetMarkerSelected: function() {
      // hashにいれる
      this.marker_selected = {};
      this.marker_selected_data_items = {};
      this.marker_selected_order = {};
      this.graphEnable = false;
      // 色を更新
      this.setSelectedPinColor();
    },
    fetchDataMarkerSelected: function() {
      Object.keys(this.marker_selected).forEach(function(key) {
        this.fetchSensorData(this.marker_selected[key].url);
      }, this);
      this.zoomReset();
    },
    async fetchSensorData(sensor_id) {
      this.loading = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/sensor/data5_nt", {
          id: sensor_id,
          start_datetime: this.start_date + " " + this.start_time + ":00",
          end_datetime: this.end_date + " " + this.end_time + ":00",
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.marker_selected_data_items[sensor_id] = response.data.items;

        // UnixTimeを挿入
        this.marker_selected_data_items[sensor_id].forEach(function(item, index, array) {
          // this.items.forEach(function(item) {
          var hash = {};
          const day1 = dayjs(item["time"]);
          this.marker_selected_data_items[sensor_id][index]["ut"] = day1.unix();
        }, this);

        const length_from = Object.keys(this.marker_selected).length;
        const length_result = Object.keys(this.marker_selected_data_items)
          .length;
        if (length_from == length_result) {
          this.graphEnable = true;
          // this.changeGraph("area1", "");
          this.changeGraph(this.nowGraphValue, "");
        } else {
          // console.log("##### Loading");
        }
        return false;
      }
      this.$store.commit("auth/setApiStatus", false);
      if (response.status === UNPROCESSABLE_ENTITY) {
        return false;
      } else {
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    changeGraph: function(value, event) {
      const length_result = Object.keys(this.marker_selected_data_items).length;
      if (length_result != 0) {
        this.nowGraphValue = value;
        this.updateData();
      }
    },
    makeDataBySensorId(sensor_id) {
      // SUM START ------------
      var myDatas = [];
      this.graphLabels = [];
      var lastUnixTime = 0;
      var diff_time_sum = 0;
      var item_sum = 0;
      var item_value_sum = 0;
      this.marker_selected_data_items[sensor_id].forEach(function(item, index, array) {
      //this.items.forEach(function(item, index, array) {
        // ------- 210727
        if (index + 1 < this.marker_selected_data_items[sensor_id].length) {
          var diff_time = this.marker_selected_data_items[sensor_id][index + 1]["ut"] - this.marker_selected_data_items[sensor_id][index]["ut"];
          diff_time_sum = diff_time_sum + diff_time;
          item_sum++;
          item_value_sum = item_value_sum + item[this.nowGraphValue];
        }

        if (diff_time_sum >= this.sitemsValue && this.sitemsValue != 0) {
          var hash = {};
          const day1 = dayjs(item["time"]);
          hash["x"] = day1.format("YYYY-MM-DD HH:mm:ss");
          hash["y"] = item_value_sum / item_sum;
          //hash["y"] = item_value_sum;
          //hash["y"] = item[this.nowGraphValue];
          myDatas.push(hash);
          diff_time_sum = 0;
          item_sum = 0;
          item_value_sum = 0;
        }

        if (this.sitemsValue == 0) {
          var hash2 = {};
          const day1 = dayjs(item["time"]);
          hash2["x"] = day1.format("YYYY-MM-DD HH:mm:ss");
          hash2["y"] = item[this.nowGraphValue];
          myDatas.push(hash2);
        }
        //myDatas[] = { 'x': item['time'], 'y': item[this.nowGraphValue] };
      }, this, sensor_id);
      // SUM END ------------

      // Before Bl 21 8/29 ST
      /*
      var myDatas = [];
      this.graphLabels = [];
      this.marker_selected_data_items[sensor_id].forEach(function(item) {
        var hash = {};
        const day1 = dayjs(item["time"]);
        hash["x"] = day1.format("YYYY-MM-DD HH:mm:ss");
        hash["y"] = item[this.nowGraphValue];
        this.graphLabels.push(hash["x"]);
        myDatas.push(hash);
      }, this);
      */

      const my_order = this.marker_selected_order[sensor_id];
      const my_color = this.colors[my_order];
      const data = {
        type: "line",
        label: this.marker_selected[sensor_id].title,
        borderColor: my_color,
        backgroundColor: "rgba(0,0,0,0)",
        borderWidth: 1,
        tension: 0.0,
        pointBorderColor: "rgba(75,192,192,1)",
        pointBorderWidth: 0.0,
        pointHoverRadius: 5,
        pointRadius: 1.5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        data: myDatas,
      };// Before Bl 21 8/29 END
      return data;
    },



    updateData() {

      var datas = [];
      Object.keys(this.marker_selected_data_items).forEach(function(key) {
        const data = this.makeDataBySensorId(key);

        datas.push(data);
        
      }, this);
      this.$set(
        this.options.scales.y.title,
        "text",
        this.nowGraphValueHash[this.nowGraphValue].unit
      );
      this.datacollection = {
        datasets: datas,
      };
    },
    initData() {
      var myLabels = this.marker_selected_data_items[11].map(
        (item) => item.time
      );
      var myLabels2 = [];
      var myLabels3 = [];
      myLabels.forEach(function(element) {
        const day1 = dayjs(element);
        myLabels2.push(day1.format("D HH:mm"));
        myLabels3.push(day1.format("M/D HH"));
      });
      this.graphLabels = myLabels2;
      this.graphLabelsMonth = myLabels3;
      this.updateData();
    },
    onZoom: function(chart) {},
    onZoomStart: function(chart, event, point) {},
    onZoomComplete: function(chart) {},
    zoomReset: function(value, event) {
      this.$refs.graph1.resetZoom();
    },
    graphOnLoaded() {
      this.loading = false;
    },
    async fetchGpsData() {
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/sensor/gps_byteam_nt", {
          team_id: this.$route.params.team_id,
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.$store.commit("auth/setApiStatus", true);
        this.marker_items = response.data.items;
        this.setPins();
        const now = dayjs(); // 現在の日付情報を取得
        this.date = [now.format("YYYY-MM-DD")];
        this.start_date = [now.format("YYYY-MM-DD")];
        this.end_date = [now.add(1, "d").format("YYYY-MM-DD")];
        if (response.data.items[0].id == this.$route.params.id) {
          // this.dateDone(this.date);
        } else {
          //this.$router.
          // this.dateDone(this.date);
          //push("/sensorgraph6/" + response.data.items[0].id);
        }
        return false;
      }
      this.$store.commit("auth/setApiStatus", false);
      if (response.status === UNPROCESSABLE_ENTITY) {
        this.loading = false;
        this.$router.push("/teams/");
        return false;
      } else {
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    setSelectedPinColor() {
      this.makers.forEach(function(item) {
        if (this.marker_selected[item.url]) {
          //var pos = { lat: latitude, lng: longitude };
          const my_order = this.marker_selected_order[item.url];
          const my_color = this.colors[my_order];
          if (item.icon.scale != 0) this.map.panTo(item.position);
          this.nowItem = item.title;
          item.setIcon({
            fillColor: my_color, //塗り潰し色
            fillOpacity: 0.6, //塗り潰し透過率
            path: window.google.maps.SymbolPath.CIRCLE, //円を指定
            scale: item.icon.scale == 0 ? 0 : 16, //円のサイズ
            strokeColor: my_color, //枠の色
            strokeWeight: 1.0, //枠の透過率
          });
        } else {
          item.setIcon({
            fillColor: "#444444", //塗り潰し色
            fillOpacity: 0.6, //塗り潰し透過率
            path: window.google.maps.SymbolPath.CIRCLE, //円を指定
            scale: item.icon.scale == 0 ? 0 : 14, //円のサイズ
            strokeColor: "#444444", //枠の色
            strokeWeight: 1.0, //枠の透過率
          });
        }
      }, this);
    },
    setPins() {
      this.marker_items.forEach(function(item) {
        this.setPosGoogleMap2(
          item.longitude,
          item.latitude,
          item.name,
          item.id,
          item.gps_status
        );
      }, this);
    },
    setPosGoogleMap2(longitude, latitude, name, id, gps_status) {
      var pos = { lat: latitude, lng: longitude };
      if (longitude && (gps_status == 1 || gps_status == 2))
        this.map.panTo(pos);
      var scale = longitude && (gps_status == 1 || gps_status == 2) ? 16 : 0;
      var marker = new window.google.maps.Marker({
        position: pos,
        map: this.map,
        url: id,
        title: name,
        icon: {
          fillColor: "#333333", //塗り潰し色
          fillOpacity: 0.6, //塗り潰し透過率
          path: window.google.maps.SymbolPath.CIRCLE, //円を指定
          scale: scale, //円のサイズ
          strokeColor: "#333333", //枠の色
          strokeWeight: 1.0, //枠の透過率
        },
        label: {
          text: name, //ラベル文字
          color: "#FFFFFF", //文字の色
          fontSize: "20px", //文字のサイズ
        },
      });
      this.makers.push(marker);
      window.google.maps.event.addListener(
        marker,
        "click",
        (function(url, mythis) {
          return function() {
            var selected = false;
            Object.keys(mythis.marker_selected).forEach(function(key) {
              if (this.marker_selected[key].url == url) {
                selected = true;
              }
            }, mythis);
            if (selected) return;
            const length = Object.keys(mythis.marker_selected).length;
            mythis.marker_selected_order[url] = length;
            mythis.$set(mythis.marker_selected, url, marker);
            // 色を更新
            mythis.setSelectedPinColor();
          };
        })(id, this)
      );
    },
    setPosGoogleMap: function(longitude, latitude) {
      var pos = { lat: latitude, lng: longitude };
      this.map.panTo(pos);
      new window.google.maps.Marker({ position: pos, map: this.map });
    },
  },
};
</script>

<style>
.small {
  max-width: 600px;
  /*margin: 150px auto;*/
}

.maru {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  background: rgba(0, 255, 0, 0.5);
  font-size: 34px;
  color: white;
  /*margin-left:20px;*/
  line-height: 80px; /*縦中央*/
  text-align: center; /*横中央*/
  border: solid 3px #00ff00;
  margin-left: 15px;
}

.maru_s {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  background: rgba(0, 255, 0, 0.5);
  font-size: 24px;
  color: white;
  /*margin-left:20px;*/
  line-height: 37px; /*縦中央*/
  text-align: center; /*横中央*/
  /*border: solid 2px rgba(0, 255, 0, 1);*/
  margin-left: 10px;
}

.progress {
  padding-top: 39px;
}

.datecol {
  /*<!--<v-col cols="4" style="background-color: #FF00FF; padding-top: 25px;">-->*/
  /*background-color: #ff0000;*/
  text-align: center; /*横中央*/
  /*padding-top: 25px;*/
}

.csvdl {
  /*background-color: #ffff00;*/
  padding-top: 35px;
}

.btnbox {
  /* background-color: #00FFFF; */
  padding-top: 6px;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}

.mapbox {
  height: 640px;
  margin-left: 10px;
  margin-right: 20px;
  /* background-color: #00FF00; */
}

.chartbox {
  /* background-color: #00FFFF; */
}
.chartAreaWrapper {
  width: 80%;
  overflow-x: scroll;
}

sm.v-data-table {
  width: 200px;
}

.v-data-table tr td:nth-of-type(1) {
  /* border-bottom: none !important; */
  padding: 0px;
  margin: 0px;
  /*background-color: #00FFFF;
  width: 10px;*/
}
.v-data-table tr td:nth-of-type(1).text-start {
  padding: 0px 10px;
  margin: 0px;
}

.v-data-table tr td:nth-of-type(2) {
  /* border-bottom: none !important; */
  padding: 0px;
  margin: 0px;
  /* background-color: #cccccc;
  width: 190px;*/
}

.v-data-table tr td:nth-of-type(2).text-left {
  padding: 0px 1px;
  margin: 0px;
}
.v-application--is-ltr .v-data-footer__pagination {
  padding: 0px 1px;
  margin: 0px;
}

.action {
  /*background-color: #0000FF;*/
  padding: 0px;
  margin: 0px;
}

.action2 {
  /* background-color: #00FF00;*/
  padding: 0px;
  margin: 0px;
}

.time {
  display: inline-block;
  text-align: center;
  font-size: 15px;
  vertical-align: middle;
  /*margin-left:20px;*/
  line-height: 15px; /*縦中央*/
  text-align: center; /*横中央*/
  /*border: solid 1px #00ff00;*/
  margin-right: 6px;
  margin-top: -0px;
}

@media (max-width: 600px) {
  .v-data-table {
    width: 90%;
  }

  .maru {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    background: rgba(0, 255, 0, 0.5);
    font-size: 24px;
    color: white;
    /*margin-left:20px;*/
    line-height: 46px; /*縦中央*/
    text-align: center; /*横中央*/
    border: solid 3px #00ff00;
    margin-left: -10px;
    margin-top: 4px;
  }

  .maru_s {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    background: rgba(0, 255, 0, 0.5);
    font-size: 18px;
    color: white;
    /*margin-left:20px;*/
    line-height: 30px; /*縦中央*/
    text-align: center; /*横中央*/
    /*border: solid 2px rgba(0, 255, 0, 1);*/
    margin-left: 5px;
  }

  .progress {
    padding-top: 28px;
  }

  .datecol {
    /*<!--<v-col cols="4" style="background-color: #FF00FF; padding-top: 25px;">-->*/
    /*background-color: #ff0000;*/
    text-align: center; /*横中央*/
    padding-top: 12px;
  }

  .csvdl {
    text-align: center; /*横中央*/
    padding-top: 25px;
  }

  .btnbox {
    margin-right: 3px;
    margin-left: 0px;
  }

  .mapbox {
    height: 260px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .chartbox {
    /* background-color: #0000FF; */
    margin-left: -10px;
    margin-right: -10px;
  }
}
</style>
