<template>
  <v-container>
    <!--ユーザ一覧ダイアログ-->
    <v-dialog v-model="user_dialog" max-width="500px">
      <v-card>
        <v-container>
          <h2 v-if="isCreate">ユーザーリスト</h2>
          <h2 v-if="!isCreate">更新</h2>

          <v-data-table
            :headers="user_headers"
            :items="user_items"
            :footer-props="{
              itemsPerPageOptions: [itemsPerPage],
              showFirstLastPage: true,
            }"
            class="elevation-1"
            @update:page="fetchSensors"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                x-small
                dark
                color="success"
                @click="addUser(item)"
                v-if="!item.team_joined"
              >
                追加
              </v-btn>
              <v-btn
                x-small
                dark
                color="error"
                @click="delUser(item)"
                v-if="item.team_joined"
              >
                削除
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>

    <!--入力フォーム-->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container>
          <h2 v-if="isCreate">センサー新規追加</h2>
          <h2 v-if="!isCreate">更新</h2>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!--名前-->
            <v-text-field
              v-model="customer.title"
              :rules="nameRules"
              :counter="20"
              label="名前"
              required
            ></v-text-field>
            <!--名前-->
            <v-text-field
              v-model="customer.sensor_name"
              :rules="nameSearchRules"
              :counter="20"
              label="テーブル検索名"
              required
            ></v-text-field>
            <!--追加ボタン-->
            <v-btn v-if="isCreate" :disabled="!valid" @click="createCustomer">
              追加
            </v-btn>
            <!--更新ボタン-->
            <v-btn v-if="!isCreate" :disabled="!valid" @click="updateCustomer">
              更新
            </v-btn>
            <v-btn @click="clear">クリア</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <v-col cols="12" sm="12" class="" style="text-align:center;" v-if="loading">
      <v-progress-linear
        v-if="loading"
        style="margin-top: 0px;"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>

    <!--ツールバー-->
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>{{ team.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!--<v-btn
        color="primary"
        dark
        class="mb-2"
        @click="showDialogUser"
        style="margin-right:8px;"
        >ユーザー追加</v-btn
      >-->
      <!--<v-btn color="primary" dark class="mb-2" @click="showDialogNew"
        >センサー新規追加</v-btn
      >-->
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :footer-props="{
        itemsPerPageOptions: [itemsPerPage],
        showFirstLastPage: true,
      }"
      class="elevation-1"
      @update:page="fetchSensors"
    >
      <!--<template v-slot:item.actions="{ item }">-->
        <!--<v-icon class="mr-2" @click="openGraph(item)">
          mdi-chart-line
        </v-icon>-->
        <!--<v-icon class="mr-2" @click="openGraphAll(item)">
          mdi-chart-areaspline
        </v-icon>-->
        <!--<v-icon class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>-->
        <!--<v-icon @click="deleteConfirm(item.id)">
          mdi-delete
        </v-icon>-->
      <!--</template>-->
    </v-data-table>

    <!-- 削除確認ダイアログを追加 -->
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">削除確認</v-card-title>
        <v-card-text>ID:{{ deleteID }}を削除してもよろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false"
            >キャンセル</v-btn
          >
          <v-btn color="green darken-1" text @click="deleteItem(deleteID)"
            >削除</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { OK, CREATED, UNPROCESSABLE_ENTITY } from '../util'
import { OK, UNPROCESSABLE_ENTITY } from "../util";
export default {
  data() {
    return {
      team: null,
      loading: false,
      model: {
        email: "",
        password: "",
      },
      headers: [
        { text: "ID", value: "id", align: "start", sortable: false },
        { text: "Title", value: "title", sortable: false },
        { text: "sensorName", value: "sensor_name", sortable: false },
        //{ text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      page: 1,
      itemsPerPage: 10,
      total: 0,

      user_headers: [
        { text: "ID", value: "id", align: "start", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      user_items: [],
      user_currentPage: 0,
      user_lastPage: 0,
      user_dialog: false,

      items: [],
      currentPage: 0,
      lastPage: 0,

      deleteDialog: false, // 追加：初期値は非表示
      deleteID: null, // 追加：削除Itemのid
      // フォーム入力値
      customer: {
        id: "",
        title: "",
        sensor_name: "",
      },
      // バリデーション
      valid: true,
      nameRules: [
        (v) => !!v || "名前は必須項目です",
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      nameSearchRules: [
        (v) => !!v || "検索名は必須項目です",
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      // ローディングの表示フラグ
      progress: false,
      // ダイアログの表示フラグ
      dialog: false,
      // 新規・更新のフラグ
      isCreate: true,
    };
  },
  mounted() {},
  methods: {
    openGraph(item) {
      console.log("### methods ###");
      console.log(item);
      /*
      if (item.type == 1) {
        //this.$router.push("/to/" + item.id);
        this.$router.push("/to/" + this.$route.params.team_id + "/" + item.id);
      } else if (item.type == 2) {
        this.$router.push("/mo/" + item.id);
      } else if (item.type == 3) {
        this.$router.push("/no/" + item.id);
      }
      */
    },
    openGraphAll(item) {
      if (item.type == 1) {
        this.$router.push("/ta/" + this.$route.params.team_id);
      } else if (item.type == 2) {
        this.$router.push("/ma/" + this.$route.params.team_id);
      } else if (item.type == 3) {
        this.$router.push("/na/" + this.$route.params.team_id);
      }
    },
    async delUser(item) {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/teams/deluser", {
          user_id: item.id,
          team_id: this.$route.params.team_id,
        })
        .catch((err) => err.response || err);

      if (response.status === OK) {
        this.progress = false;
        await this.fetchUsers();
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        console.log(response.data.errors);
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        // this.dialog = false
        // this.progress = false
        // context.commit("error/setCode", response.status, { root: true });
      }
    },
    async addUser(item) {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/teams/adduser", {
          user_id: item.id,
          team_id: this.$route.params.team_id,
        })
        .catch((err) => err.response || err);

      if (response.status === OK) {
        this.progress = false;
        await this.fetchUsers();
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        console.log(response.data.errors);
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        // this.dialog = false
        // this.progress = false
        // context.commit("error/setCode", response.status, { root: true });
      }
    },
    async fetchUsers() {
      const response = await this.$axios.get(
        `/api/users/?page=${this.page}&team_id=${this.$route.params.team_id}`
      );
      if (response.status !== OK) {
        this.$store.commit("error/setCode", response.status);
        return false;
      }
      // this.sensors = response.data.data
      this.user_items = response.data.items;
      this.user_currentPage = response.data.current_page;
      this.user_lastPage = response.data.last_page;
    },
    deleteConfirm(id) {
      this.deleteDialog = true;
      this.deleteID = id;
    },
    deleteItem(id) {
      this.$axios
        .delete("/api/sensors/" + id)
        .then((response) => {
          console.log(response.data);
          if (response.status === OK) {
            this.fetchSensors();
            return false;
          }
          //context.commit('setApiStatus', false)
          //context.commit('error/setCode', response.status, { root: true })
        })
        .catch(function(error) {
          console.error(error);
        });
      this.deleteDialog = false;
    },

    editItem(item) {
      this.customer.id = item.id;
      this.customer.title = item.title;
      this.customer.sensor_name = item.sensor_name;
      this.isCreate = false;
      this.dialog = true;
    },
    async loginCheck() {
      await this.$store.dispatch("auth/currentUser", this);
      //await store.dispatch('auth/currentUser', this)
      // トップページに移動する
      // this.$router.push('/');
    },
    async createCustomer() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/sensors", {
          title: this.customer.title,
          sensor_name: this.customer.sensor_name,
          team_id: this.$route.params.team_id,
        })
        .catch((err) => err.response || err);

      if (response.status === OK) {
        // context.commit("setApiStatus", true);
        // context.commit("setUser", response.data);
        this.dialog = false;
        this.progress = false;
        await this.fetchSensors();
        return false;
      }
      // context.commit("setApiStatus", false);
      if (response.status === UNPROCESSABLE_ENTITY) {
        console.log(response.data.errors);
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        // this.dialog = false
        // this.progress = false
        // context.commit("error/setCode", response.status, { root: true });
      }
      console.log(response);
    },
    async teamDetail() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .get(`/api/teams/${this.$route.params.team_id}`, {
          // id: this.customer.id,
        })
        .catch((err) => err.response || err);
      console.log(response);
      if (response.status === OK) {
        this.progress = false;
        // await this.fetchSensors();
        this.team = response.data.item;
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        console.log(response.data.errors);
      } else {
        this.progress = false;
      }
    },
    async updateCustomer() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .put(`/api/sensors/${this.customer.id}`, {
          // id: this.customer.id,
          title: this.customer.title,
          sensor_name: this.customer.sensor_name,
        })
        .catch((err) => err.response || err);

      if (response.status === OK) {
        this.dialog = false;
        this.progress = false;
        await this.fetchSensors();
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        console.log(response.data.errors);
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        console.log("### updateCustomer 7");
        this.dialog = false;
        this.progress = false;
        // context.commit("error/setCode", response.status, { root: true });
      }
    },
    // --------------------------------
    // フォームのクリア
    // --------------------------------
    clear: function() {
      this.$refs.form.reset();
    },
    showDialogUser: function() {
      // this.isCreate = true;
      this.user_dialog = true;
      this.clear(); // MUST this.dialog = true;
    },
    // --------------------------------
    // 新規追加ダイアログの表示
    // --------------------------------
    showDialogNew: function() {
      this.isCreate = true;
      this.dialog = true;
      this.clear(); // MUST this.dialog = true;
    },
    // --------------------------------
    // 更新ダイアログの表示
    // --------------------------------
    showDialogUpdate: function(id, name, gender) {
      this.customer.id = id;
      this.customer.name = name;
      this.customer.gender = gender;
      this.isCreate = false;
      this.dialog = true;
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteCustomer: function(name, id) {
      if (!confirm(name + id + "を削除してもよろしいですか？")) {
        return;
      }
    },
    async fetchSensors() {
      this.loading = true;
      //const response = await this.$axios.get(`/api/sensors/?page=${this.page}`, {
      const response = await this.$axios.post(`/api/sensor/byteam`, {
        page: this.page,
        team_id: this.$route.params.team_id,
      });
      this.loading = false;
      if (response.status !== OK) {
        this.$store.commit("error/setCode", response.status);
        return false;
      }
      // console.log(response.data);
      this.items = response.data.data;
      this.currentPage = response.data.current_page;
      this.lastPage = response.data.last_page;
    },
  },
  beforeCreate() {
    console.log("### Sensors beforeCreate ");
  },
  created() {
    console.log("### Sensors Created ");
    this.loginCheck();
  },

  watch: {
    $route: {
      async handler() {
        await this.teamDetail();
        await this.fetchSensors();
        await this.fetchUsers();
      },
      immediate: true,
    },
  },
};
</script>
