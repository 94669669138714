<template>
  <v-container>
    <v-dialog v-model="alert_dialog" max-width="500px">
      <v-card>
        <v-container>
          <h2>アラート設定</h2>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="setting.alert_area_start"
              :counter="10"
              label="計算に使う帯域"
              required
            ></v-text-field>
            <v-text-field
              v-model="setting.alert_calc_num"
              :counter="10"
              label="平均移動ビン数"
              required
            ></v-text-field>
            <v-text-field
              v-model="setting.alert_threshold"
              :counter="10"
              label="閾値"
              required
            ></v-text-field>
            <v-btn :disabled="!valid" @click="storeSetting">
              更新
            </v-btn>
            <!--<v-btn @click="clear">クリア</v-btn>-->
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <!--ユーザ一覧ダイアログ-->
    <v-dialog v-model="user_dialog" max-width="500px">
      <v-card>
        <v-container>
          <h2 v-if="isCreate">ユーザーリスト</h2>
          <h2 v-if="!isCreate">更新</h2>

          <v-data-table
            :headers="user_headers"
            :items="user_items"
            :footer-props="{
              itemsPerPageOptions: [itemsPerPage],
              showFirstLastPage: true,
            }"
            class="elevation-1"
            @update:page="fetchSensors"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                x-small
                dark
                color="success"
                @click="addUser(item)"
                v-if="!item.team_joined"
              >
                追加
              </v-btn>
              <v-btn
                x-small
                dark
                color="error"
                @click="delUser(item)"
                v-if="item.team_joined"
              >
                削除
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>

    <!--入力フォーム-->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container>
          <h2 v-if="isCreate">センサー新規追加</h2>
          <h2 v-if="!isCreate">更新</h2>
          <h4 v-if="isDupError" style="color:#FF0000">センサー名が重複しています</h4>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!--名前-->
            <v-text-field
              v-model="customer.title"
              :rules="nameRules"
              :counter="20"
              label="表示用名"
              required
            ></v-text-field>
            <!--名前-->
            <v-text-field
              v-model="customer.sensor_name"
              :rules="nameSearchRules"
              :counter="20"
              label="センサー名"
              required
            ></v-text-field>

                    <a href="https://www.google.com/maps/" target="_blank">位置を調べる</a>                                  
            <v-text-field
              v-model="customer.latitude"
              :counter="20"
              label="latitude 緯度"
            >
              </v-text-field>
            <v-text-field
              v-model="customer.longitude"
              :counter="20"
              label="longitude 経度"
            ></v-text-field>
            <v-switch
                v-model="customer.alert_flag"
                label="アラート通知"
                color="red"
                hide-details
                style="margin-bottom: 18px;"
            ></v-switch>
            <!--<v-text-field
              v-model="customer.mqtt_server"
              :rules="mqttRules"
              :counter="50"
              label="MQTT Server"
              required
            ></v-text-field>
            <v-text-field
              v-model="customer.mqtt_user"
              :rules="mqttRules"
              :counter="50"
              label="MQTT User"
              required
            ></v-text-field>
            <v-text-field
              v-model="customer.mqtt_toserver"
              :rules="mqttRules"
              :counter="50"
              label="MQTT ToS"
              required
            ></v-text-field>
            <v-text-field
              v-model="customer.mqtt_toclient"
              :rules="mqttRules"
              :counter="50"
              label="MQTT ToC"
              required
            ></v-text-field>-->
            <!--追加ボタン-->
            <v-btn @click="gpsURL">
              座標を確認
            </v-btn>
            <v-btn v-if="isCreate" :disabled="!valid" @click="createSensor">
              追加
            </v-btn>
            <!--更新ボタン-->
            <v-btn v-if="!isCreate" :disabled="!valid" @click="updateCustomer">
              更新
            </v-btn>
            <v-btn @click="clear">クリア</v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 削除確認ダイアログを追加 -->
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">削除確認</v-card-title>
        <v-card-text>ID:{{ deleteID }}を削除してもよろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false"
            >キャンセル</v-btn
          >
          <v-btn color="green darken-1" text @click="deleteItem(deleteID)"
            >削除</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-col cols="12" sm="12" class="" style="text-align:center;" v-if="loading">
      <v-progress-linear
        v-if="loading"
        style="margin-top: 0px;"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>

    <!--ツールバー-->
    <v-toolbar flat color="grey lighten-2">
      <v-toolbar-title>{{ team.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
            style="width:100px;margin-right:8px;"
          >
      </v-text-field>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="showDialogUser"
        style="margin-right:8px;"
        >ユーザー追加</v-btn
      >
      <v-btn color="primary" dark class="mb-2" @click="showDialogNew" style="margin-right:8px;"
        >センサー新規追加</v-btn
      >
      <v-btn color="primary" dark class="mb-2" @click="showDialogAlert"
        >アラート設定</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="100"
      :footer-props="{
        itemsPerPageOptions: [itemsPerPage],
        showFirstLastPage: true,
      }"
      class="elevation-1"
      @update:page="fetchSensors"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="openGraph(item)">
          mdi-chart-line
        </v-icon>
        <v-icon class="mr-2" @click="openGraphAll(item)">
          mdi-chart-areaspline
        </v-icon>
        <v-icon class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteConfirm(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    
  </v-container>
</template>

<script>
// import { OK, CREATED, UNPROCESSABLE_ENTITY } from '../util'
import { OK, UNPROCESSABLE_ENTITY } from "../util";

export default {
  data() {
    return {
      isDupError: false,
      search: "",
      myLatLng: { lat: 34.6692668, lng: 135.4760877 },
      map: "",
      team: null,
      loading: false,
      model: {
        email: "",
        password: "",
      },
      headers: [
        // { text: "ID", value: "id", align: "start", sortable: true },
        { text: "表示用名", value: "title", sortable: true },
        { text: "センサー名", value: "sensor_name", sortable: true },
        { text: "アラート", value: "alert_flag", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      page: 1,
      itemsPerPage: 100,
      total: 0,

      user_headers: [
        { text: "ID", value: "id", align: "start", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
      user_items: [],
      user_currentPage: 0,
      user_lastPage: 0,
      user_dialog: false,
      alert_dialog: false,
      items: [],
      currentPage: 0,
      lastPage: 0,
      deleteDialog: false, // 追加：初期値は非表示
      deleteID: null, // 追加：削除Itemのid
      // フォーム入力値
      customer: {
        id: "",
        title: "",
        sensor_name: "",
        longitude: "",
        latitude: "",
        alert_flag: 1,
        //mqtt_server: "",
        //mqtt_user: "",
        //mqtt_toserver: "",
        //mqtt_toclient: "",
      },

      // バリデーション
      valid: true,
      nameRules: [
        (v) => !!v || "表示用名は必須項目です",
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      nameSearchRules: [
        (v) => !!v || "センサー名は必須項目です",
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      mqttRules: [
        (v) => (v && v.length <= 50) || "50文字以内で入力してください",
      ],
      gpsRules: [
        (v) => (v && v.length <= 20) || "20文字以内で入力してください",
      ],
      settingRules: [
        (v) => (v && v.length <= 10) || "10文字以内で入力してください",
      ],
      setting: {
        id: "",
        alert_calc_num: "",
        alert_area_start: "",
        alert_threshold: "",
      },
      // ローディングの表示フラグ
      progress: false,
      // ダイアログの表示フラグ
      dialog: false,
      // 新規・更新のフラグ
      isCreate: true,
    };
  },
  mounted() {

    
  },
  methods: {
    openGraphAll(item) {
      if (this.team.type == 1) {
        this.$router.push("/ta/" + this.$route.params.team_id);
      } else if (this.team.type == 2) {
        this.$router.push("/ma/" + this.$route.params.team_id);
      } else if (this.team.type == 3) {
        this.$router.push("/na/" + this.$route.params.team_id);
      } 
    },
    deleteConfirm(id) {
      this.deleteDialog = true;
      this.deleteID = id;
    },
    deleteItem(id) {
      this.$axios
        .delete("/api/sensors/" + id)
        .then((response) => {
          if (response.status === OK) {
            this.fetchSensors();
            return false;
          }
          this.$store.commit("error/setCode", response.status, { root: true });
        })
        .catch(function(error) {
          console.error(error);
        });
      this.deleteDialog = false;
    },
    openGraph(item) {
      if (this.team.type == 1) {
        this.$router.push("/to/" + this.$route.params.team_id + "/" + item.id);
      } else if (this.team.type == 2) {
        this.$router.push("/mo/" + this.$route.params.team_id + "/" + item.id);
      } else if (this.team.type == 3) {
        this.$router.push("/no/" + this.$route.params.team_id + "/" + item.id);
      }
    },
    editItem(item) {
      this.customer.id = item.id;
      this.customer.title = item.title;
      this.customer.sensor_name = item.sensor_name;
      this.customer.longitude = item.longitude;
      this.customer.latitude = item.latitude;
      this.customer.alert_flag = item.alert_flag;
      console.log("### item.alert_flag)");
      console.log(item.alert_flag);

      //this.customer.mqtt_server = item.mqtt_server;
      //this.customer.mqtt_user = item.mqtt_user;
      //this.customer.mqtt_toserver = item.mqtt_toserver;
      //this.customer.mqtt_toclient = item.mqtt_toclient;
      this.isCreate = false;
      this.dialog = true;
      this.isDupError = false;
    },
    async loginCheck() {
      await this.$store.dispatch("auth/currentUser", this);
      //await store.dispatch('auth/currentUser', this)
      // トップページに移動する
      // this.$router.push('/');
    },
    async addUser(item) {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/teams/adduser", {
          user_id: item.id,
          team_id: this.$route.params.team_id,
        })
        .catch((err) => err.response || err);
      this.progress = false;
      if (response.status === OK) {
        await this.fetchUsers();
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        this.dialog = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    async delUser(item) {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/teams/deluser", {
          user_id: item.id,
          team_id: this.$route.params.team_id,
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.progress = false;
        await this.fetchUsers();
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        this.progress = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    async fetchUsers() {
      const response = await this.$axios.get(
        `/api/users/?page=${this.page}&team_id=${this.$route.params.team_id}`
      );
      if (response.status !== OK) {
        this.$store.commit("error/setCode", response.status);
        return false;
      }
      this.user_items = response.data.items;
      this.user_currentPage = response.data.current_page;
      this.user_lastPage = response.data.last_page;
    },
    gpsURL() {
      var url = 'https://www.google.com/maps/?q=' + this.customer.latitude + ',' + this.customer.longitude + '&z=25';

       window.open(url);

    },
    async createSensor() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post("/api/sensors", {
          title: this.customer.title,
          sensor_name: this.customer.sensor_name,
          team_id: this.$route.params.team_id,
          longitude: this.customer.longitude,
          latitude: this.customer.latitude,
          alert_flag: this.customer.alert_flag,
          // mqtt_server: this.customer.mqtt_server,
          // mqtt_user: this.customer.mqtt_user,
          // mqtt_toserver: this.customer.mqtt_toserver,
          // mqtt_toclient: this.customer.mqtt_toclient,
        })
        .catch((err) => err.response || err);
      this.progress = false;
      if (response.status === OK) {
        this.dialog = false;
        this.isDupError = false;
        await this.fetchSensors();
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        //console.log("### UNPROCESSABLE_ENTITY ###");
        this.isDupError = true;
        //context.commit("setLoginErrorMessages", response.data.errors);

      } else {
        this.dialog = false;
        this.isDupError = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    async updateCustomer() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .put(`/api/sensors/${this.customer.id}`, {
          // id: this.customer.id,
          title: this.customer.title,
          sensor_name: this.customer.sensor_name,
          longitude: this.customer.longitude,
          latitude: this.customer.latitude,
          alert_flag: this.customer.alert_flag,
          // mqtt_server: this.customer.mqtt_server,
          // mqtt_user: this.customer.mqtt_user,
          // mqtt_toserver: this.customer.mqtt_toserver,
          // mqtt_toclient: this.customer.mqtt_toclient,
        })
        .catch((err) => err.response || err);
      this.progress = false;
      if (response.status === OK) {
        this.dialog = false;
        await this.fetchSensors();
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        this.dialog = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    async teamDetail() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .get(`/api/teams/${this.$route.params.team_id}`, {
          // id: this.customer.id,
        })
        .catch((err) => err.response || err);
      if (response.status === OK) {
        this.progress = false;
        // await this.fetchSensors();
        this.team = response.data.item;
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        // console.log(response.data.errors);
      } else {
        this.progress = false;
      }
    },
    async fetchSensors() {
      this.loading = true;
      //const response = await this.$axios.get(`/api/sensors/?page=${this.page}`, {
      const response = await this.$axios.post(`/api/sensor/byteam`, {
        page: this.page,
        team_id: this.$route.params.team_id,
      });
      this.loading = false;
      if (response.status !== OK) {
        this.$store.commit("error/setCode", response.status);
        return false;
      }
      this.items = response.data.data;
      this.currentPage = response.data.current_page;
      this.lastPage = response.data.last_page;
    },
    // --------------------------------
    // 設定
    // --------------------------------
    async storeSetting() {
      this.progress = true;
      this.$axios.get("/sanctum/csrf-cookie");
      const response = await this.$axios
        .post(`/api/sensor/setting_store`, {
          team_id: this.$route.params.team_id,
          alert_calc_num: this.setting.alert_calc_num,
          alert_area_start: this.setting.alert_area_start,
          alert_threshold: this.setting.alert_threshold,
        })
        .catch((err) => err.response || err);
      this.progress = false;
      if (response.status === OK) {
        this.alert_dialog = false;
        await this.fetchSetting();
        return false;
      }
      if (response.status === UNPROCESSABLE_ENTITY) {
        //context.commit("setLoginErrorMessages", response.data.errors);
      } else {
        // this.dialog = false;
        this.$store.commit("error/setCode", response.status, { root: true });
      }
    },
    async fetchSetting() {
      this.loading = true;
      //const response = await this.$axios.get(`/api/sensors/?page=${this.page}`, {
      const response = await this.$axios.post(`/api/sensor/setting_detail`, {
        team_id: this.$route.params.team_id,
        // team_id: this.$route.params.team_id,
      });
      this.loading = false;
      if (response.status !== OK) {
        this.$store.commit("error/setCode", response.status);
        return false;
      }
      // console.log(response.data);
      // console.log(response.data.item.alert_area_start);
     // console.log(response.data['alert_area_start']);
     this.setting = response.data.item;

     // this.setting.alert_area_start = 22;
    },
    // --------------------------------
    // フォームのクリア
    // --------------------------------
    clear: function() {
      this.$refs.form.reset();
    },
    // --------------------------------
    // ダイアログの表示
    // --------------------------------
    showDialogUser: function() {
      // this.isCreate = true;
      this.user_dialog = true;
      this.clear(); // MUST this.dialog = true;
    },
    showDialogNew: function() {
      console.log("### showDialogNew");
      this.isCreate = true;
      this.dialog = true;
      this.isDupError = false;
      this.customer.id = '';
      this.customer.title = '';
      this.customer.sensor_name = '';
      this.customer.longitude = '';
      this.customer.latitude = '';
      this.customer.alert_flag = 1;
      this.$refs.form.resetValidation();
      //this.clear(); // MUST this.dialog = true;
    },
    showDialogUpdate: function(id, name, gender) {
      this.customer.id = id;
      this.customer.name = name;
      this.customer.gender = gender;
      this.isCreate = false;
      this.dialog = true;
    },
    showDialogAlert: function() {
      this.alert_dialog = true;
      // this.isDupError = false;
      //this.clear(); // MUST this.dialog = true;
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteCustomer: function(name, id) {
      if (!confirm(name + id + "を削除してもよろしいですか？")) {
        return;
      }
    },
  },
  beforeCreate() {
    // console.log("### Sensors beforeCreate ");
  },
  created() {
    // console.log("### Sensors Created ");
    this.loginCheck();
  },
  watch: {
    $route: {
      async handler() {
        await this.teamDetail();
        await this.fetchSensors();
        await this.fetchUsers();
        await this.fetchSetting();
      },
      immediate: true,
      
    },
  },
};
</script>